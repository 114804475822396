export type OptionsTypes = {
  method?: 'get' | 'post';
  headers?: { [key: string]: string };
  body?: string;
  timeout?: number;
};

const fetchWithTimeout = async (resource: string, options: OptionsTypes) => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    credentials: 'include',
    signal: controller.signal
  });

  clearTimeout(id);

  return response;
};

export default fetchWithTimeout;
