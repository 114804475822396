import Cookies from 'js-cookie';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  REACT_APP_API_HOST,
  ACCESS_TOKEN_EXPIRATION_KEY,
  OKTA_LOGIN_PATH
} from './appConstants';

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ operation, forward, graphQLErrors }) => {
      const unauthorizedError = graphQLErrors?.find((graphQLError) => (
        graphQLError.extensions && graphQLError.extensions.code === 401
      ))

      if (unauthorizedError) {
        Cookies.remove(ACCESS_TOKEN_EXPIRATION_KEY);
        window.location.assign(OKTA_LOGIN_PATH);
      }

      forward(operation)
    }),
    new HttpLink({
      uri: `${REACT_APP_API_HOST}/v1/graphql`,
      credentials: 'include'
    })
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' }
  }
});

export default client
