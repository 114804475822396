import { useTranslation } from 'react-i18next';
import styles from './DocumentsTable.module.css'
import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg';

const DocumentsPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.documentsPlaceholder}>
        <div className={styles.icon}>
            <DocumentIcon />
        </div>
        <div className={styles.title}>
          <strong>{t('documents.emptyPlaceholder.title')}</strong>
        </div>
        <div className={styles.message}>{t('documents.emptyPlaceholder.message')}</div>
    </div>
  )
}

export default DocumentsPlaceholder
