import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import AddressFormField from './AddressFormField';
import { useAddressFormatter } from './useAddressFormatter';
import { FieldName } from '@shopify/address';
import styles from './AddressForm.module.css';
import { addressFields } from './constants';
import { CountryCode } from 'libphonenumber-js';
import { ProfileFormValues } from '../ProfileForm/types';

export type FormattedLabelsType = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  zone: string;
  [key: string]: string;
};

type Props = {
  handleCountryChange: (value: CountryCode) => void;
  isProfileEditMode?: boolean;
}

const AddressForm = ({ handleCountryChange, isProfileEditMode }: Props) => {
  const { values } = useFormikContext<ProfileFormValues>();

  const { countryCode } = values.address;
  const { orderedFields } = useAddressFormatter(countryCode);

  const renderAddressFormField = (fieldName: FieldName) => {
    const mappedFieldName = addressFields[fieldName];
    if (!mappedFieldName) return null;

    return (
      <div className={styles.formFieldPadding} key={mappedFieldName}>
        <AddressFormField
          fieldName={fieldName}
          formPath='address'
          handleCountryChange={handleCountryChange}
          isProfileEditMode={isProfileEditMode}
        />
      </div>
    );
  };

  const renderedFields = useMemo(() => (
    orderedFields?.flat().map((fieldName: FieldName, index: number) => (
      <React.Fragment key={`${index}-${fieldName}`}>
        {renderAddressFormField(fieldName)}
      </React.Fragment>
    ))
  ), [orderedFields]);

  return <>{renderedFields}</>;
};

export default AddressForm;
