import { useState } from 'react';
import { post } from 'utils/helpers/api';

type UseCreateUserType = {
  createUser: (
    username: string,
    email: string,
    password: string,
    paymentInvitationId: string,
    countryCode: string,
    callback: () => void
  ) => void;
  inProgress: boolean;
  errors: string[] | undefined;
  dismissErrors: () => void;
};

const useCreateUser = (): UseCreateUserType => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const dismissErrors = () => {
    setErrors([]);
  };

  const createUser = (
    username: string,
    email: string,
    password: string,
    paymentInvitationId: string,
    countryCode: string,
    callback: () => void
  ) => {
    setInProgress(true);
    post('/v1/user/new', {
      body: JSON.stringify({
        credentials: {
          username,
          email,
          password
        },
        payment_invitation_id: paymentInvitationId,
        country_code: countryCode
      })
    })
      .then((data) => {
        if (data.ok) {
          data.json().then(() => {
            callback && callback();
          });
        } else {
          data.json().then((value) => {
            const errors = value.message.split(',').map((e: string) => e.trim());
            setErrors(errors);
          });
        }
      })
      .catch((err: Error) => setErrors([err.message]))
      .finally(() => setInProgress(false));
  };

  return { createUser, inProgress, errors, dismissErrors };
};

export default useCreateUser;
