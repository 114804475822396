import { createContext } from 'react';
import { CurrentStripeMemberDetailsResType } from 'graphql/queries/StripeCustomerQueries';

type StripeAccountDetailsContextType = {
  stripeAccountDetails?: CurrentStripeMemberDetailsResType;
  setStripeAccountDetails: (newStripeAccountDetails: CurrentStripeMemberDetailsResType) => void
  refetch: () => void;
}

const StripeAccountDetails = createContext<StripeAccountDetailsContextType | null>(null);

export default StripeAccountDetails;
