import Confetti from 'components/Confetti';
import { useTranslation } from 'react-i18next';
import useConfetti from 'hooks/useConfetti';
import styles from './OnboardingSuccessAlert.module.css';

type Props = {
  companyName: string;
}

const OnboardingSuccessAlert = ({ companyName }: Props) => {
  const { t } = useTranslation();
  const { fire, getInstance } = useConfetti();

  return (
    <>
      <span onClick={fire}>
        {t('onboarding.alert', { companyName: companyName })}&nbsp;
        <u className={styles.celebrate}>{t('onboarding.letsCelebrate')}</u>
      </span>
      <Confetti getInstance={getInstance} />
    </>
  );
};

export default OnboardingSuccessAlert;
