import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import { GET_MEMBER_ONBOARDING_QUERY_NAME, QUERY_MEMBER_ONBOARDING } from 'graphql/queries/MemberOnboardingQueries';
import { OnboardingContext } from 'contexts/Onboarding'
import { Spinner } from 'assets/milo';
import { personaEnabled } from 'utils/helpers/flags';

type OnboardingRequiredType = {
  children: JSX.Element;
  redirect?: string;
};

const OnboardingRequired = ({
  children
}: OnboardingRequiredType) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { data, loading, error } = useQueryWithErrors(
    GET_MEMBER_ONBOARDING_QUERY_NAME,
    QUERY_MEMBER_ONBOARDING
  );

  const onboardingContext = useContext(OnboardingContext)

  if (loading) { return <Spinner variation='black' /> }

  if (error) {
    return (
      <p>
        {t('common.error')}: {error.message}
      </p>
    );
  }

  const workflowState = data?.memberOnboarding?.details?.workflowState
  if (workflowState === 'complete' || onboardingContext?.isOnboarded) {
    return children
  } else {
    const onboardingStepZeroStates = ['member_info', 'address', 'service_provided']
    const personalPath = '/onboarding/personal-info'
    const paymentPath = '/onboarding/payment-info'
    const personaVerificationPath = '/onboarding/persona-verification'

    if (onboardingStepZeroStates.includes(workflowState) && location.pathname !== personalPath) {
      return <Navigate to={personalPath} state={{ from: location }} />;
    } else if (workflowState === 'bank_account' && location.pathname !== paymentPath) {
      return <Navigate to={paymentPath} state={{ from: location }} />;
    } else if (personaEnabled() && workflowState === 'persona' && location.pathname !== personaVerificationPath) {
      return <Navigate to={personaVerificationPath} state={{ from: location }} />;
    }

    return <Navigate to={'/onboarding'} state={{ from: location }} />;
  }
};

export default OnboardingRequired;
