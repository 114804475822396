import React from 'react';

type Props = {
  className?: string;
}

const defaultProps = {
  className: undefined
};

const Check = ({ className }: Props) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <g id='Check'>
      <path fill='currentColor' d='M7.55,14.88a1.39,1.39,0,0,1-1-.41L4,11.93A1.39,1.39,0,0,1,4,10,1.4,1.4,0,0,1,6,10l1.61,1.61L14.06,5.5a1.31,1.31,0,0,1,1-.37,1.34,1.34,0,0,1,.95.43h0a1.38,1.38,0,0,1-.06,1.94l-7.45,7A1.39,1.39,0,0,1,7.55,14.88Z' />
    </g>
  </svg>
);

Check.defaultProps = defaultProps;

export default Check
