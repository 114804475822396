import { gql } from '@apollo/client';

export const GET_CURRENT_COMPANY_MEMBERS_QUERY_NAME = 'CurrentCompanyMembers';

export type QueryCurrentCompanyMembersResType = {
  currentCompanyMembers: {
    id: string;

    emailAddress: string;

    companyId: string;
    memberId: string;
    paymentInvitationId: string;

    company: {
      details: {
        name: string;
      }
    }
    details: {
      status: string;
      createdAt: string;
    }
  }[]
}

export const QUERY_CURRENT_COMPANY_MEMBERS = gql`
  query ${GET_CURRENT_COMPANY_MEMBERS_QUERY_NAME} {
    currentCompanyMembers {
      id

      emailAddress

      companyId
      memberId
      paymentInvitationId

      company {
        details {
          name
        }
      }
      details {
        status
        createdAt
      }
    }
  }
`
