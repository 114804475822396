import { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LDProvider } from 'launchdarkly-react-client-sdk';

// Contexts
import PageTitle from 'contexts/PageTitle';
import AppAlertsProvider, { useAppAlerts } from 'contexts/AppAlerts';
import StripeAccountDetails from 'contexts/StripeAccountDetails';
import { PaymentsContextProvider } from 'contexts/Payments';
import { OnboardingContextProvider } from 'contexts/Onboarding';
import { SupportedCountriesContextProvider } from 'contexts/SupportedCountries';
import { ToastProvider } from 'contexts/Toast';

// Hooks
import useLogin from 'hooks/useLogin';

// Queries
import {
  CurrentStripeMemberDetailsResType,
  QUERY_CURRENT_STRIPE_MEMBER_DETAILS
} from 'graphql/queries/StripeCustomerQueries';
import { AllRoutes } from './routes';
import { DocumentsContextProvider } from 'contexts/Documents';
import { FeatureFlagProvider } from 'contexts/FeatureFlags';
import { REACT_APP_LAUNCH_DARKLY_CLIENT_ID } from 'appConstants';

const Routes = () => {
  const location = useLocation();
  const { clearAlerts } = useAppAlerts()
  useEffect(() => {
    clearAlerts()
  }, [location.pathname])

  return (<AllRoutes/>)
}

const App = () => {
  const { isUserLoggedIn } = useLogin();

  const [pageTitle, setPageTitle] = useState<string>('');
  const [
    stripeAccountDetails,
    setStripeAccountDetails
  ] = useState<CurrentStripeMemberDetailsResType>();

  const { data: stripeAccountData, refetch } = useQuery<CurrentStripeMemberDetailsResType>(
    QUERY_CURRENT_STRIPE_MEMBER_DETAILS,
    {
      skip: !isUserLoggedIn(),
      pollInterval: 300000 // 5 minutes
    }
  );

  useEffect(() => {
    setStripeAccountDetails(stripeAccountData);
  }, [stripeAccountData]);

  return (
    <LDProvider clientSideID={REACT_APP_LAUNCH_DARKLY_CLIENT_ID}>
      <FeatureFlagProvider>
        <PageTitle.Provider value={{ pageTitle, setPageTitle }}>
          <StripeAccountDetails.Provider
            value={{ stripeAccountDetails, setStripeAccountDetails, refetch }}
          >
            <PaymentsContextProvider>
              <DocumentsContextProvider>
                <OnboardingContextProvider>
                    <BrowserRouter>
                      <AppAlertsProvider>
                        <ToastProvider>
                          <SupportedCountriesContextProvider>
                            <Routes/>
                          </SupportedCountriesContextProvider>
                        </ToastProvider>
                      </AppAlertsProvider>
                    </BrowserRouter>
                </OnboardingContextProvider>
              </DocumentsContextProvider>
            </PaymentsContextProvider>
          </StripeAccountDetails.Provider>
        </PageTitle.Provider>
      </FeatureFlagProvider>
    </LDProvider>
  );
};

export default App;
