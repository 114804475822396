import { useTranslation } from 'react-i18next';

import {
  QUERY_SYSTEM_DETAILS,
  queryName
} from 'graphql/queries/SystemDetailsQueries';

import styles from './SystemDetailsPage.module.css';
import useQueryWithErrors from 'hooks/useQueryWithErrors';

import { Spinner } from 'assets/milo';

function handleRollbarJsTest() {
  throw new Error('SYSTEM DETAILS TEST');
}

const SystemDetailsPage = () => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data: { systemDetails } = { systemDetails: [] }
  } = useQueryWithErrors(queryName, QUERY_SYSTEM_DETAILS);

  if (loading) { return <Spinner variation='black' /> }

  if (error) { return <p>{t('common.error')}: {error.message}</p> }

  return (
    <article className={styles.content}>
      <h1>System</h1>

      <p>Lo-fi system dashboard for debugging non-business logic.</p>

      <section>
        <h2>Rollbar.js</h2>

        <p>Force an exception to validate that Rollbar.js works.</p>

        <button onClick={handleRollbarJsTest}>Test Rollbar</button>
      </section>

      <section>
        <h2>Container</h2>

        <p>Information about the Fargate container.</p>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>key</th>
              <th>value</th>
            </tr>
          </thead>

          <tbody>
            {Object.entries<Array<[string, string]>>(systemDetails).map((detail) => {
              return (
                <tr key={detail[0]}>
                  <td>{detail[0]}</td>
                  <td>{detail[1]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </article>
  );
}
export default SystemDetailsPage;
