type ObjectType = { [key: string]: any }

/**
 * Take in any key-value pair object and output the flattened version with all
 * nested values floated to the surface level
 *
 * Example:
 *  flattenObject(
 *   {
 *      a: {
 *        b: 1,
 *        c: 2,
 *      },
 *      d: '3'
 *   }
 *  )
 *  Returns:
 *    {
 *      b: 1,
 *      c: 2,
 *      d: '3'
 *    }
 *
 * @param obj - the original Object to be flattened
 * @param out - the Object to insert the values into (default: {})
 * @return - an Object with all key-value pairs available on the top level
 */
const flattenObject = (obj: ObjectType, out = {} as ObjectType): ObjectType => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      out = flattenObject(obj[key], out)
    } else {
      out[key] = obj[key]
    }
  })

  return out
}

export default flattenObject;
