import { REACT_APP_ENV } from 'appConstants';

export const personaEnabled = () => {
  const env : { [key: string]: boolean } = {
    production: true,
    development: true,
    staging: true,
    test: true
  }
  return env[REACT_APP_ENV];
}

export const documentsEnabled = () => {
  const env : { [key: string]: boolean } = {
    production: false,
    development: true,
    staging: true,
    test: true
  }
  return env[REACT_APP_ENV];
}

export const bankAccountTypeEnabled = () => {
  const env : { [key: string]: boolean } = {
    production: true,
    development: true,
    staging: true,
    test: true
  }
  return env[REACT_APP_ENV];
}

export const bankAccountTypeModalEnabled = () => {
  const env : { [key: string]: boolean } = {
    production: true,
    development: true,
    staging: true,
    test: true
  }
  return env[REACT_APP_ENV];
}

export const nationalIdEnabled = () => {
  const env : { [key: string]: boolean } = {
    production: true,
    development: true,
    staging: true,
    test: true
  }
  return env[REACT_APP_ENV];
}

export const isDevOnly = () => {
  return REACT_APP_ENV === 'development'
}
