import * as PropTypes from 'prop-types';
import { VARIANTS } from './constants';

import BaseLogoIcon from './icons/JustworksLogo';
import OnDarkLogoIcon from './icons/JustworksLogoOnDark';
// import WhiteLogoIcon from './icons/justworks-logo-white.svg';
// import GreyLogoIcon from './icons/justworks-logo-grey.svg';
// import PrideLogoIcon from './icons/justworks-logo-pride.svg';
// import PrideWhiteLogoIcon from './icons/justworks-logo-pride-white.svg';

const LogoIcon = ({ className, variant }: { className?: string, variant: string }) => {
  let DisplayLogo;
  switch (variant) {
    case VARIANTS.ON_DARK:
      DisplayLogo = OnDarkLogoIcon;
      break;
    // case VARIANTS.WHITE:
    //   DisplayLogo = WhiteLogoIcon;
    //   break;
    // case VARIANTS.GREY:
    //   DisplayLogo = GreyLogoIcon;
    //   break;
    // case VARIANTS.PRIDE:
    //   DisplayLogo = PrideLogoIcon;
    //   break;
    // case VARIANTS.PRIDE_WHITE:
    //   DisplayLogo = PrideWhiteLogoIcon;
    //   break;
    case VARIANTS.BLACK:
    default:
      DisplayLogo = BaseLogoIcon;
  }
  return <DisplayLogo className={className} data-testid='logo' />;
};

LogoIcon.propTypes = {
  variant: PropTypes.oneOf([
    VARIANTS.BLACK,
    VARIANTS.ON_DARK,
    VARIANTS.WHITE,
    VARIANTS.GREY,
    VARIANTS.PRIDE,
    VARIANTS.PRIDE_WHITE
  ])
};
LogoIcon.defaultProps = {
  variant: 'base'
};

export default LogoIcon;
