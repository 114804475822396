import { gql } from '@apollo/client';

export const UPDATE_PERSONA_INQUIRY_ID_MUTATION_NAME = 'updatePersonaInquiryId';

export const UPDATE_PERSONA_INQUIRY_ID = gql`
  mutation(
    $memberId: String!
    $inquiryId: String!
    $completed: Boolean!
  ){
    ${UPDATE_PERSONA_INQUIRY_ID_MUTATION_NAME}(
      input: {
        memberId: $memberId
        inquiryId: $inquiryId
        completed: $completed
      }
    ) {
      details {
        personaInquiryId
      }
    }
  }
`
