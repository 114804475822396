import { Button, Dialog } from '@justworkshr/milo-components'
import { CURRENT_BANK_ACCOUNT_QUERY, CURRENT_BANK_ACCOUNT_QUERY_NAME, MUTATION_UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON, QueryCurrentBankAccountResType, UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON_MUTATION_NAME } from 'graphql/queries/BankAccountQueries'
import useMutationWithErrors from 'hooks/useMutationWithErrors'
import useQueryWithErrors from 'hooks/useQueryWithErrors'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { bankAccountTypeModalEnabled } from 'utils/helpers/flags'

const BankAccountTypeDialog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: bankAccountData, loading: bankAccountDataLoading, refetch } = useQueryWithErrors<QueryCurrentBankAccountResType>(
    CURRENT_BANK_ACCOUNT_QUERY_NAME,
    CURRENT_BANK_ACCOUNT_QUERY,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const isOpen = bankAccountTypeModalEnabled() && !bankAccountData?.currentBankAccount?.details?.accountTypeAcknowledgedOn

  const [updateBankAccountTypeAcknowledgedOn] = useMutationWithErrors(
    UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON_MUTATION_NAME,
    MUTATION_UPDATE_BANK_ACCOUNT_TYPE_ACKNOWLEDGED_ON,
    {
      onCompleted: () => {
        refetch()
      }
    }
  );

  const submit = () => {
    updateBankAccountTypeAcknowledgedOn({
      variables: {
        bankAccountId: bankAccountData?.currentBankAccount.id
      }
    })
  }

  const goToBankAccountForm = () => {
    submit()
    navigate('/bank-account/edit')
  }

  const close = () => {
    submit()
  }

  if (bankAccountDataLoading || !isOpen) {
    return null
  }

  return (
    <Dialog
      title={t('bankAccount.accountTypeDialog.title')}
      isOpen={isOpen}
      size='sm'
      showCloseButton={false}
      primaryButton={
        <Button onClick={close} variant='filled'>
          {t('bankAccount.accountTypeDialog.closeButton')}
        </Button>
      }
      secondaryButton={
        <Button onClick={goToBankAccountForm} variant='ghost'>
          {t('bankAccount.accountTypeDialog.updateBankAccountLink')}
        </Button>
      }
    >
      <Trans i18nKey='bankAccount.accountTypeDialog.content' />
    </Dialog>
  )
}

export default BankAccountTypeDialog
