import './Alert.css';

import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { TYPES, ALIGNMENTS } from './constants';

/**
 * The **Alert** component renders a banner informing the user of something. Depending on the information
 * being conveyed, an Alert can be a notice, warning, error message, or success message.
 *
 * This pattern has also been referred to as a 'flash' or 'flash message', because it is frequently
 * used to render the contents of the Rails flash.
 */
const Alert = ({
  type,
  visible,
  alignContent,
  onDismiss,
  children,
  className
}) => {
  const classes = classNames('children', alignContent);

  return (
    <section
      className={classNames('Alert', type, { visible }, className)}
      role='alert'
      aria-live='assertive'
      hidden={!visible}
    >
      {(() => {
        if (typeof children === 'string') {
          return <p className={classes}>{children}</p>;
        } else {
          return <div className={classes}>{children}</div>;
        }
      })()}

      <button
        className={classNames('dismiss', { visible: !!onDismiss })}
        onClick={onDismiss}
        aria-label='Close'
      >
        <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
           width='13px' height='13px' viewBox='0 0 13 13' enableBackground='new 0 0 13 13' xmlSpace='preserve'>
        <g>
          <g>
            <rect x='-2.2' y='6' transform='matrix(0.7071 0.7071 -0.7071 0.7071 6.5002 -2.6924)' width='17.4' height='0.9'/>
          </g>
          <g>
            <rect x='6' y='-2.2' transform='matrix(0.7071 0.7071 -0.7071 0.7071 6.5 -2.6924)' width='0.9' height='17.4'/>
          </g>
        </g>
        </svg>
      </button>
    </section>
  );
};

Alert.propTypes = {
  /** Custom classes to merge with the root class name */
  className: PropTypes.string,

  /** Whether the alert banner is visible or not */
  visible: PropTypes.bool,

  /** Handler for when a user clicks the X */
  onDismiss: PropTypes.func,

  /** The style of the alert */
  type: PropTypes.oneOf([
    TYPES.SUCCESS,
    TYPES.NOTICE,
    TYPES.ERROR,
    TYPES.WARNING
  ]),

  /** The alignment of the alert text */
  alignContent: PropTypes.oneOf([ALIGNMENTS.LEFT, ALIGNMENTS.CENTER]),

  /** The content for the alert */
  children: PropTypes.node
};

Alert.defaultProps = {
  visible: true,
  type: TYPES.NOTICE,
  alignContent: ALIGNMENTS.LEFT
};

export default Alert;
