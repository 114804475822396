import { gql } from '@apollo/client';

export const GET_MEMBER_ONBOARDING_QUERY_NAME = 'MemberOnboarding';

export type MemberOnboardingDetailType = {
  workflowState: string;
  personaInquiryId: string | null;
}

export type MemberOnboardingType = {
  memberOnboarding: {
    memberId: string;
    details: MemberOnboardingDetailType;
  }
}

export const QUERY_MEMBER_ONBOARDING = gql`
  query ${GET_MEMBER_ONBOARDING_QUERY_NAME} {
    memberOnboarding {
      memberId
      details {
        workflowState
        personaInquiryId
      }
    }
  }
`
