import {
  useMutation,
  DocumentNode,
  TypedDocumentNode,
  MutationHookOptions,
  OperationVariables
} from '@apollo/client';
import useGraphqlErrors from 'hooks/useGraphqlErrors';

function useMutationWithErrors<TData = any, TVariables = OperationVariables>(
  operationName: string,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
) {
  const mutationResponse = useMutation<TData, TVariables>(
    mutation,
    {
      ...options,
      onCompleted: (data) => {
        /* `onCompleted` is supposed to only run when no errors are present.
         * That currently is not functioning correctly. I opened an issue on their
         * repo ( https://github.com/apollographql/apollo-client/issues/9499 ) to
         * see about getting this fixed.
         */
        if (!data) {
          return false;
        }

        let possibleErrors = false;
        Object.keys(data).every((key) => {
          if ((data as { [key: string]: any })[key] === null) {
            possibleErrors = true;
            return false;
          }

          return true;
        })

        if (!possibleErrors && options?.onCompleted) {
          options.onCompleted(data);
        }
      }
    }
  );

  useGraphqlErrors(operationName, mutationResponse[1]?.error);

  return mutationResponse
};

export default useMutationWithErrors;
