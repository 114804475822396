import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Spinner } from 'assets/milo';

import {
  CREATE_BANK_ACCOUNT_MUTATION_NAME,
  MUTATION_CREATE_BANK_ACCOUNT,
  QueryCurrentBankAccountResType
} from 'graphql/queries/BankAccountQueries';
import {
  QUERY_CURRENT_ADDRESS_DETAILS,
  GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME,
  QueryCurrentAddressDetailsResType
} from 'graphql/queries/AddressDetailQueries';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { useAppAlerts, AlertTypes } from 'contexts/AppAlerts';
import flattenObject from 'utils/helpers/flattenObject';
import AutoForm from 'components/Forms/AutoForm';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import useMutationWithErrors from 'hooks/useMutationWithErrors';
import styles from './BankAccountForm.module.css';

type FormType = {
  [key: string]: string
}

type Props = {
  onBack?: () => void,
  onCompleted?: () => void,
  submitButtonText?: string,
  countryCode?: string,
  currentBankAccount?: QueryCurrentBankAccountResType,
  setIsEditing?: (isEditing: boolean) => void
}

const BankAccountForm = ({ onBack, onCompleted, submitButtonText, countryCode, currentBankAccount, setIsEditing }: Props) => {
  const { t } = useTranslation();
  const { supportedCountryMap } = useContext(SupportedCountriesContext);
  const { pushAlerts } = useAppAlerts();
  const [realCountryCode, setRealCountryCode] = useState<string>(countryCode || '');

  // Grab address/country details from the previous step
  const { data: addressData } = useQueryWithErrors<QueryCurrentAddressDetailsResType>(
    GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME,
    QUERY_CURRENT_ADDRESS_DETAILS,
    {
      // Skip if we have a real country code
      skip: !!realCountryCode,
      // Ensure we're getting fresh data
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (countryCode) {
      setRealCountryCode(countryCode)
    } else {
      if (addressData?.currentAddressDetails) {
        setRealCountryCode(addressData.currentAddressDetails.countryCode)
      }
    }
  }, [countryCode, addressData]);

  const [addBankAccountDetail, { loading, error }] = useMutationWithErrors(
    CREATE_BANK_ACCOUNT_MUTATION_NAME,
    MUTATION_CREATE_BANK_ACCOUNT,
    {
      onCompleted
    }
  );

  // Setup what happens when the form is submitted
  const onSubmit = (values: FormType) => {
    if (values.country) {
      const country = supportedCountryMap[values.country]

      if (!country) {
        pushAlerts({
          message: t('errors.countryNotFound'),
          type: AlertTypes.Error
        });

        return;
      }

      addBankAccountDetail({
        variables: {
          country: country?.alpha2Code,
          currency: country?.currencyCode,
          bankAccountId: currentBankAccount?.currentBankAccount?.id,
          ...flattenObject(values)
        }
      })
    }
  }

  // Make sure we have all of our information available before rendering
  if (!realCountryCode) {
    return <Spinner variation='black' />;
  }

  return (
    <AutoForm
      layout='vertical'
      onBack={onBack}
      onSubmit={onSubmit}
      submitButtonText={submitButtonText}
      isSubmitting={loading}
      apolloErrors={error}
      countryCode={realCountryCode}
      editMode={!!currentBankAccount}
      setIsEditing={setIsEditing}
      tosText={!currentBankAccount ? (<Trans i18nKey='legal.bankAccount'>
          <Link
            to='/terms-of-service'
            target='_blank'
            className={styles.legalLink}
          >
            International Payment Terms
          </Link>
          <a
            href='https://www.justworks.com/terms-of-service'
            target='_blank'
            className={styles.legalLink}
          >
            Terms of Use
          </a>
          <a
            href='https://www.justworks.com/legal'
            target='_blank'
            className={styles.legalLink}
          >
            Privacy Policies
          </a>
          <a
            href='https://stripe.com/connect-account/legal/recipient'
            target='_blank'
            className={styles.legalLink}
          >
            Stripe Recipient Agreement
          </a>
        </Trans>) : null
    }
    />
  )
}

export default BankAccountForm;
