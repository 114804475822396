import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import usePageTitle from 'hooks/usePageTitle';
import ProfileForm from 'components/Forms/ProfileForm';
import VerticalStepper from 'components/VerticalStepper';
import { Spinner } from 'assets/milo';
import useQueryWithErrors from 'hooks/useQueryWithErrors';

import styles from '../Onboarding.module.css';
import { GET_MEMBER_ONBOARDING_QUERY_NAME, QUERY_MEMBER_ONBOARDING } from 'graphql/queries/MemberOnboardingQueries';
import { OnboardingSteps, mapWorkflowToEnum } from '../utils/constants';

const OnboardingPersonalInfo = () => {
  const { t } = useTranslation();

  usePageTitle(t('onboarding.onboarding'));
  const navigate = useNavigate();

  const { data, loading } = useQueryWithErrors(
    GET_MEMBER_ONBOARDING_QUERY_NAME,
    QUERY_MEMBER_ONBOARDING,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const workflowState = data?.memberOnboarding?.details?.workflowState
  useEffect(() => {
    if (workflowState === 'complete') {
      navigate('/dashboard');
    }
  }, [data])

  const nextStep = () => {
    navigate('/onboarding/payment-info');
  };

  const handleStepClick = (stepNumber:number) => {
    if (stepNumber === OnboardingSteps.bankingInfo) {
      navigate('/onboarding/payment-info');
    }
    if (stepNumber === OnboardingSteps.persona) {
      navigate('/onboarding/persona-verification');
    }
  }

  if (loading) { return <Spinner variation='black' /> }

  return (
    <div className={styles.content}>
      <div className={styles.container} data-testid='onboarding-container'>
        <aside>
        <VerticalStepper
            steps={[
              t('onboarding.yourProfile'),
              t('onboarding.paymentInfo'),
              t('onboarding.persona')
            ]}
            currentStep={OnboardingSteps.personalInfo}
            onClick={handleStepClick}
            status={mapWorkflowToEnum[workflowState]}
          />
        </aside>
        <div>
          <h1 className={styles.title}>{t('onboarding.completeProfile')}</h1>
          <ProfileForm
            onCompleted={nextStep}
            submitButtonText={t('onboarding.saveAndContinue')}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingPersonalInfo;
