import ToastList from 'components/ToastList';
import { useState, createContext, ReactNode } from 'react';

export type ToastType = ToastContent & {
  id: number
}

type ToastContent = {
  content: ReactNode,
  type: string
}

export type ToastContextType = {
  addToast: (toast: ToastContent) => void
}

export const ToastContext = createContext<ToastContextType>({
  addToast: () => null
});

export const ToastProvider = ({ children }: { children: JSX.Element }) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const clearToast = (id: number) => {
    setToasts(prev => prev.filter(toast => toast.id !== id))
  }

  const addToast = (toast: ToastContent) => {
    const newToast = { ...toast, id: new Date().getTime() }
    setToasts([newToast, ...toasts])
    setTimeout(() => {
      clearToast(newToast.id)
    }, 10000)
  }

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastList toasts={toasts} clearToast={clearToast} />
    </ToastContext.Provider>
  );
};
