import fetchWithTimeout, { OptionsTypes } from './fetchWithTimeout';
import { REACT_APP_API_HOST } from 'appConstants';

const post = async (pathname: string, options?: OptionsTypes) => {
  return fetchWithTimeout(`${REACT_APP_API_HOST}${pathname}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    ...options
  });
};

const get = async (pathname: string) => {
  return fetchWithTimeout(`${REACT_APP_API_HOST}${pathname}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' }
  });
};

export { post, get };
