import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import {
  Alert,
  Button,
  Form,
  FormField,
  TextInput
} from 'assets/milo';
import { TYPES as ALERT_TYPES } from 'assets/milo/Alert/constants';
import FormFieldErrorMessage from 'components/Forms/FormFieldErrorMessage';
import PasswordInput from 'components/Inputs/PasswordInput';
import InputValidationHelper from 'components/Inputs/InputValidationHelper'
import PasswordRegex from 'utils/regex/password';
import UsernameRegex, { usernameValidationHelpers } from 'utils/regex/username';

import useCreateUser from 'hooks/useCreateUser';
import usePageTitle from 'hooks/usePageTitle';

import styles from './CreateAccountForm.module.css';
import { OKTA_LOGIN_PATH } from 'appConstants';

type Props = {
  paymentInvitationId: string,
  countryCode: string
}

const url = `${OKTA_LOGIN_PATH}?redirect=/onboarding/personal-info`;

const CreateAccount: FC<Props> = ({ paymentInvitationId, countryCode }: Props) => {
  const { t } = useTranslation();
  usePageTitle(t('member.createAccount'));
  const { createUser, inProgress, errors: createUserErrors, dismissErrors } = useCreateUser();
  const [showUsernameHelper, setShowUsernameHelper] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [errorBannerText, setErrorBannerText] = useState<string>(t('account.createAccountError'));
  const [initialErrors, setInitialErrors] = useState<{ email?: string, username?: string }>({});
  const emailAddressFieldRef = useRef<HTMLInputElement>(null);
  const usernameFieldRef = useRef<HTMLInputElement>(null);

  const formMessage = (key: string, options: Record<string, unknown> = {}) => t(`formValidations.${key}`, options);

  useEffect(() => {
    createUserErrors?.forEach((error) => {
      switch (error) {
        case 'emailTaken':
          if (!initialErrors.email) {
            setInitialErrors((oldErrors) => (
              {
                ...oldErrors,
                email: formMessage(error)
              }
            ))
          }
          break;
        case 'usernameTaken':
          if (!initialErrors.username) {
            setInitialErrors((oldErrors) => (
              {
                ...oldErrors,
                username: formMessage(error)
              }
            ))
          }
          break;
        case 'invalidPaymentInvitation':
          setErrorBannerText(t('paymentInvitation.invalidPaymentInvitation'));
          setShowErrorBanner(true);
          break;
        default:
          setErrorBannerText(t('account.createAccountError'));
          setShowErrorBanner(true);
          break;
      }
    })

    if (createUserErrors?.includes('emailTaken')) {
      emailAddressFieldRef?.current?.scrollIntoView({ behavior: 'smooth' });
      emailAddressFieldRef?.current?.focus({ preventScroll: true });
    } else if (createUserErrors?.includes('usernameTaken')) {
      usernameFieldRef?.current?.scrollIntoView({ behavior: 'smooth' });
      usernameFieldRef?.current?.focus({ preventScroll: true });
    } else if (createUserErrors?.length) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [createUserErrors]);

  const CreateAccountSchema = Yup.object().shape({
    username: Yup.string()
      .min(4, formMessage('tooShort', { min: 4 }))
      .max(40, formMessage('tooLong', { max: 40 }))
      .matches(UsernameRegex, t('member.usernameRegex'))
      .required(formMessage('required')),
    email: Yup.string()
      .email(formMessage('invalidEmail'))
      .required(formMessage('required')),
    password: Yup.string()
      .matches(PasswordRegex, t('member.passwordRegex'))
      .required(formMessage('required'))
  });

  return (
    <>
      <Alert
        type={ALERT_TYPES.ERROR}
        onDismiss={() => {
          dismissErrors();
          setShowErrorBanner(false)
        }}
        visible={showErrorBanner}
      >
        {errorBannerText}
      </Alert>

      <Formik
        initialValues={{
          username: '',
          email: '',
          password: '',
          passwordConfirmation: ''
        }}
        initialErrors={initialErrors}
        enableReinitialize
        validationSchema={CreateAccountSchema}
        validateOnChange={false}
        onSubmit={(values) => {
          setInitialErrors({});
          dismissErrors();

          createUser(
            values.username,
            values.email,
            values.password,
            paymentInvitationId,
            countryCode,
            () => window.location.assign(`${url}&login_hint=${values.username}`)
          );
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, values }) => (
          <Form
            onSubmit={handleSubmit}
            layout='vertical'
            data-testid='create-account-form'
          >
            <FormField labelContent={<>{t('common.email')}</>}>
              <TextInput
                id='email'
                name='email'
                type='email'
                placeholder={t('common.email')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                refProp={emailAddressFieldRef}
                data-cy='email'
              />
              <FormFieldErrorMessage name='email' />
            </FormField>
            <FormField labelContent={<>{t('member.username')}</>}>
              <TextInput
                id='username'
                name='username'
                type='text'
                placeholder={t('member.username')}
                onChange={handleChange}
                onBlur={(event: any) => {
                  setShowUsernameHelper(false);
                  handleBlur(event);
                }}
                value={values.username}
                onFocus={() => setShowUsernameHelper(true)}
                refProp={usernameFieldRef}
              />
              <FormFieldErrorMessage name='username' />
              {
                showUsernameHelper && (
                  <InputValidationHelper
                    validations={usernameValidationHelpers}
                    value={values.username}
                  />
                )
              }
            </FormField>
            <PasswordInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              withHelper
            />

            <Button className={styles.submitButton} loading={inProgress} onMouseDown={ (event: any) => { event.preventDefault(); } }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onMouseUp={ (event: any) => { (document.activeElement as HTMLElement).blur() } } type='submit'>
              {t('account.create')}
            </Button>

            <div>
              <Trans i18nKey='legal.createAccount'>
                <Link
                  to='/terms-of-service'
                  target='_blank'
                  className={styles.legalLink}
                >
                  International Payment Terms
                </Link>
                <a
                  href='https://www.justworks.com/terms-of-service'
                  target='_blank'
                  className={styles.legalLink}
                >
                  Terms of Use
                </a>
                <a
                  href='https://www.justworks.com/legal'
                  target='_blank'
                  className={styles.legalLink}
                >
                  Privacy Policies
                </a>
                <a
                  href='https://stripe.com/connect-account/legal/recipient'
                  target='_blank'
                  className={styles.legalLink}
                >
                  Stripe Recipient Agreement
                </a>
              </Trans>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateAccount;
