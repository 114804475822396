import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import AppHeader from 'components/AppHeader';
import usePageTitle from 'hooks/usePageTitle';

import styles from './TermsOfService.module.css';

const TermsOfService = () => {
  const { t } = useTranslation();
  usePageTitle(t('termsOfService'));

  return (
    <>
      <AppHeader />
      <main className={styles.main}>
        <article>
          <h1 className={styles.header}>{t('legal.termsOfService')}</h1>
          <Trans i18nKey='legal.termsOfServiceOpener'>
            <section className={styles.contentSection} />
          </Trans>
          <ol type='I'>
            <li>{t('legal.tos-I')}</li>
            <ol type='A'>
              <li>{t('legal.tos-I-A')}</li>
              <li>{t('legal.tos-I-B')}</li>
              <li>{t('legal.tos-I-C')}</li>
            </ol>

            <li>{t('legal.tos-II')}</li>
            <ol type='A'>
              <li>
                <Trans i18nKey='legal.tos-II-A'>
                  <a
                    href='https://stripe.com/connect-account/legal/full'
                    target='_blank'
                  />
                  <a
                    href='https://stripe.com/legal'
                    target='_blank'
                  />
                </Trans>
              </li>
              <li>{t('legal.tos-II-B')}</li>
              <li>
                <Trans i18nKey='legal.tos-II-C'>
                  <a
                    href='https://stripe.com/privacy'
                    target='_blank'
                  />
                </Trans>
              </li>
              <li>{t('legal.tos-II-D')}</li>
            </ol>

            <li>{t('legal.tos-III')}</li>
            <ol type='A'>
              <li>{t('legal.tos-III-A')}</li>
              <li>{t('legal.tos-III-B')}</li>
              <li>{t('legal.tos-III-C')}</li>
              <li>{t('legal.tos-III-D')}</li>
              <li>{t('legal.tos-III-E')}</li>
              <li>{t('legal.tos-III-F')}</li>
              <li>{t('legal.tos-III-G')}</li>
            </ol>

            <li>{t('legal.tos-IV')}</li>

            <li>{t('legal.tos-V')}</li>
            <ol type='A'>
              <li>{t('legal.tos-V-A')}</li>
              <ol type='1'>
                <li>{t('legal.tos-V-A-1')}</li>
                <li>{t('legal.tos-V-A-2')}</li>
                <li>{t('legal.tos-V-A-3')}</li>
                <li>
                  <Trans i18nKey='legal.tos-V-A-4'>
                    <a
                      href='https://stripe.com/restricted-businesses'
                      target='_blank'
                    />
                  </Trans>
                </li>
                <li>{t('legal.tos-V-A-5')}</li>
                <li>{t('legal.tos-V-A-6')}</li>
                <ol type='a'>
                  <li>{t('legal.tos-V-A-6-a')}</li>
                  <li>{t('legal.tos-V-A-6-b')}</li>
                  <li>{t('legal.tos-V-A-6-c')}</li>
                  <li>{t('legal.tos-V-A-6-d')}</li>
                  <li>{t('legal.tos-V-A-6-e')}</li>
                  <li>{t('legal.tos-V-A-6-f')}</li>
                </ol>
              </ol>
              <li>{t('legal.tos-V-B')}</li>
            </ol>

            <li>{t('legal.tos-VI')}</li>

            <li>{t('legal.tos-VII')}</li>

            <li>{t('legal.tos-VIII')}</li>
            <ol type='A'>
              <li>{t('legal.tos-VIII-A')}</li>
              <li>{t('legal.tos-VIII-B')}</li>
              <li>{t('legal.tos-VIII-C')}</li>
              <li>{t('legal.tos-VIII-D')}</li>
              <li>{t('legal.tos-VIII-E')}</li>
              <li>{t('legal.tos-VIII-F')}</li>
              <li>{t('legal.tos-VIII-G')}</li>
              <li>{t('legal.tos-VIII-H')}</li>
              <li>{t('legal.tos-VIII-I')}</li>
              <li>{t('legal.tos-VIII-J')}</li>
              <li>
                <Trans i18nKey='legal.tos-VIII-K'>
                  <div className={styles.mailingAddress} />
                </Trans>
              </li>
              <li>{t('legal.tos-VIII-L')}</li>
              <li>{t('legal.tos-VIII-M')}</li>
              <li>{t('legal.tos-VIII-N')}</li>
              <li>{t('legal.tos-VIII-O')}</li>
            </ol>
          </ol>
        </article>
      </main>
    </>
  );
}

export default TermsOfService;
