import './CheckboxInput.css';

import React, { ReactElement, ComponentProps } from 'react';
import classNames from 'classnames';

type Props = {
  /** Custom classes to merge with the root class name */
  className?: string;

  /** An optional label to the right of the checkbox */
  label?: ReactElement | string;

  /** If the input is in an error state */
  error?: boolean;

  /** If the input is disabled */
  disabled?: boolean;

  /** An optional input state where it displays minus */
  indeterminate?: boolean;
}

/**
 * A **CheckboxInput** is a styled single checkbox input.
 *
 * All unlisted props are passed directly to the underlying `input` element.
 * See the [React form docs](https://facebook.github.io/react/docs/forms.html) for more info about what props are supported.
 */
const CheckboxInput = ({
  className,
  error,
  label,
  disabled,
  indeterminate,
  ...otherProps
}: Props & ComponentProps<'input'>) => (
  <label
    className={classNames(
      'CheckboxInput',
      className,
      { error },
      { disabled },
      { indeterminate }
    )}
  >
    <input type='checkbox' disabled={disabled} {...otherProps} />
    {indeterminate ? (
      <svg className='indeterminate-graphic' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
        <g id='minus'>
          <path fill='currentColor' d='M13,9H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z'/>
        </g>
      </svg>
    ) : (
      <svg className='checkbox-graphic' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
        <g id='Check'>
          <path fill='currentColor' d='M7.55,14.88a1.39,1.39,0,0,1-1-.41L4,11.93A1.39,1.39,0,0,1,4,10,1.4,1.4,0,0,1,6,10l1.61,1.61L14.06,5.5a1.31,1.31,0,0,1,1-.37,1.34,1.34,0,0,1,.95.43h0a1.38,1.38,0,0,1-.06,1.94l-7.45,7A1.39,1.39,0,0,1,7.55,14.88Z' />
        </g>
      </svg>
    )}
    {(() => {
      if (label) {
        return <span className='checkbox-label'>{label}</span>;
      }
    })()}
  </label>
);

CheckboxInput.defaultProps = {
  className: '',
  label: '',
  error: false,
  indeterminate: false,
  disabled: false
};

export default CheckboxInput;
