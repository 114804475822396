import { gql } from '@apollo/client';
import { PaymentType } from 'utils/types/paymentType';

export const GET_ALL_PAYMENT_HISTORY_QUERY_NAME = 'GetAllPaymentHistory';

export type PaymentHistoryGqlResponseType = {
  paymentsYearToDateTotal: {
    value: number,
    currency: string
  },
  allOrSelectedPayments: PaymentType[]
}

export const QUERY_ALL_PAYMENTS = gql`
  query ${GET_ALL_PAYMENT_HISTORY_QUERY_NAME} {
    paymentsYearToDateTotal {
      value
      currency
    }
    allOrSelectedPayments(statusFilter: ["processing", "completed"]) {
      id
      companyName
      companyMemberId
      details {
        id
        paymentId
        collectionAmount
        collectionCurrency
        collectionSettledAt
        disbursementSettledAt
        status
        disbursementAmountActual
        disbursementAmountQuoted
        disbursementCurrency
        transferSettledAt
        initiatedOn
        createdAt
      }
      deliveryDateRangeStart
      deliveryDateRangeEnd
    }
  }

`
