import { ReactNode } from 'react'
import styles from './Toast.module.css'
import { SystemIcon } from '@justworkshr/milo-components'

type ToastProps = {
  children: ReactNode
  clear: () => void
  type: string
}

export const TOAST_COLOR = {
  SUCCESS: 'additive',
  ERROR: 'destructive',
  INFO: 'info'
}

const Toast = ({ children, clear, type }: ToastProps) => {
  let colorStyle;

  switch (type) {
    case TOAST_COLOR.SUCCESS:
      colorStyle = styles.success;
      break;
    case TOAST_COLOR.ERROR:
      colorStyle = styles.error;
      break;
    case TOAST_COLOR.INFO:
    default:
      colorStyle = styles.info;
      break;
  }

  return (
    <div className={`${styles.toast} ${colorStyle}`} data-testid='toastContainer'>
      <div className={styles.content}>
        {children}
      </div>
      <button
        data-testid='closeToastButton'
        className={styles.buttonStyle}
        onClick={clear}
      >
        <SystemIcon iconName='close' size='small' />
      </button>
    </div>
  )
}

export default Toast;
