import Form from './Form.jsx';
export { default as FormField } from './components/FormField';
export { default as FieldGroup } from './components/FieldGroup';
export { default as Fieldset } from './components/Fieldset';

export {
  LAYOUTS as FORM_LAYOUTS,
  SPACING as FORM_SPACING,
  METHODS as FORM_METHODS
} from './constants';

export default Form;
