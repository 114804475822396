import {
  useQuery,
  DocumentNode,
  TypedDocumentNode,
  QueryHookOptions,
  OperationVariables
} from '@apollo/client';
import useGraphqlErrors from './useGraphqlErrors';

function useQueryWithErrors<TData = any, TVariables = OperationVariables>( // eslint-disable-line @typescript-eslint/no-unused-vars
  operationName: string,
  query: DocumentNode | TypedDocumentNode<TData, OperationVariables>,
  options?: QueryHookOptions<TData, OperationVariables>
) {
  const queryResponse = useQuery<TData, OperationVariables>(query, options);

  useGraphqlErrors(operationName, queryResponse?.error);

  return queryResponse
};

export default useQueryWithErrors;
