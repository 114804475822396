import './TextInput.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * A **TextInput** is a styled input for short-form text.
 *
 * All unlisted props are passed directly to the underlying `input` element.
 * See the [React form docs](https://facebook.github.io/react/docs/forms.html) for more info about what props are supported.
 */
export default class TextInput extends Component {
  static propTypes = {
    /** Custom classes to merge with the root class name */
    className: PropTypes.string,

    /** The alignment of the input text, which is left-aligned by default */
    align: PropTypes.oneOf(['right']),

    refProp: PropTypes.oneOfType([
      // Either a function
      PropTypes.func,
      // Or the instance of a DOM native element
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  };

  render() {
    const { className, align, refProp, ...otherProps } = this.props;
    return (
      <input
        className={classNames('TextInput', className, {
          [`align-${align}`]: align
        })}
        type='text'
        ref={refProp || ((input) => {
          this.input = input;
        })}
        {...otherProps}
      />
    );
  }
}
