export enum OnboardingSteps {
  personalInfo,
  bankingInfo,
  persona,
  complete
}

export const mapWorkflowToEnum: {[value: string]: OnboardingSteps} = {
  member_info: OnboardingSteps.personalInfo,
  bank_account: OnboardingSteps.bankingInfo,
  persona: OnboardingSteps.persona
}
