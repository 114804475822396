import { Alert } from 'assets/milo';
import { useAppAlerts } from 'contexts/AppAlerts';
import { ALIGNMENTS } from 'assets/milo/Alert/constants';
import PropTypes from 'prop-types';

type AlertsContainerType = {
  alignContent?: string
}

const AlertsContainer = ({ alignContent = 'left' } : AlertsContainerType) => {
  const { state, clearAlert } = useAppAlerts()
  return (
    <>
      {
        state.appAlerts.map((appAlert, index) => {
          return <Alert
            alignContent={alignContent}
            key={index}
            type={appAlert.type}
            onDismiss={() => clearAlert(index)}>
            {appAlert.message}
          </Alert>
        })
      }
    </>
  )
}

AlertsContainer.propTypes = {
  /** The alignment of the alert text (pulled from Alert propTypes) */
  alignContent: PropTypes.oneOf([ALIGNMENTS.LEFT, ALIGNMENTS.CENTER])
};

AlertsContainer.defaultProps = {
  alignContent: 'left'
};

export default AlertsContainer
