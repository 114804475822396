import React from 'react';

import { CheckIcon } from 'components/Icons';

import './VerticalStepper.css';

interface IStepperProps {
  currentStep: number;
  steps: Array<string>;
  onClick: (stepNumber: number) => void;
  status: number;
}

const VerticalStepper = ({ ...props }: IStepperProps) => {
  const { steps, currentStep, onClick, status } = props;

  return (
    <div>
      {steps.map((step, index) => {
        const stepNumber = index;
        const isCurrentStep = currentStep === stepNumber;
        const isPreviousStep = stepNumber < status;
        const activeButton = stepNumber > status || currentStep === stepNumber ? '' : 'active-button';

        const buttonClassName = isCurrentStep ? 'current' : isPreviousStep ? 'completed' : 'to-do';

        const handleOnClick = () => {
          if (stepNumber > status) {
            return;
          }
          onClick(stepNumber);
        };

        return (
          <div key={index}>
            <div className={`step ${activeButton}`} onClick={handleOnClick}>
              <div className={`step-circle ${buttonClassName}`}>
                {isPreviousStep ? (
                  <CheckIcon className='step-check' />
                ) : (
                  <div className='step-num'>{index + 1}</div>
                )}
              </div>
              <div className={`step-title ${buttonClassName}`}>{step}</div>
            </div>
            {index < steps.length - 1 && <div className='step-stroke' />}
          </div>
        );
      })}
    </div>
  );
};

export default VerticalStepper;
