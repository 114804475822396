import Cookies from 'js-cookie';
import {
  REACT_APP_API_HOST,
  ACCESS_TOKEN_EXPIRATION_KEY
} from 'appConstants';

type UseLogoutType = {
  userLogout: () => void;
};

const useLogout = (): UseLogoutType => {
  const oktaLogoutPath = `${REACT_APP_API_HOST}/v1/_logout`

  const userLogout = () => {
    Cookies.remove(ACCESS_TOKEN_EXPIRATION_KEY);
    window.location.assign(oktaLogoutPath);
  };

  return { userLogout };
};

export default useLogout;
