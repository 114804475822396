import { MemberDetailsType } from 'graphql/queries/MemberDetailQueries';

export const nameConstructor = (memberDetails: MemberDetailsType) => {
  let displayName = '';

  if (memberDetails.preferredName && memberDetails.preferredName.length > 0) {
    displayName += memberDetails.preferredName;
  } else {
    displayName += memberDetails.firstName;
  }

  displayName += ` ${memberDetails.lastName}`;

  return displayName;
}
