import { ReactNode } from 'react'
import styles from './MenuItem.module.css'

type Props = {
    children: ReactNode
    onClick: (value: any) => void
}

const MenuItem = ({ children, onClick }: Props) => {
  return (
    <div className={styles.menuItem} onClick={onClick}>{children}</div>
  )
}

export default MenuItem
