import { datadogRum } from '@datadog/browser-rum';
import { REACT_APP_API_HOST, REACT_APP_DATADOG_APPLICATION_ID, REACT_APP_DATADOG_CLIENT_TOKEN } from 'appConstants';

export function initializeDataDog() {
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'intl-api-fe',
    allowedTracingUrls: [REACT_APP_API_HOST],
    env: process.env.REACT_APP_ENV || 'development',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_CODE_VERSION || '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
}
