import React from 'react';

type Props = {
  className?: string;
}

const defaultProps = {
  className: undefined
};

const EyeSlash = ({ className }: Props) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
  >
    <g id='Eye_Slash-2' data-name='Eye Slash'>
      <path fill='currentColor' d='M15.38,6l1.32-1.32a1,1,0,0,0-1.41-1.41L13.67,4.92A8.13,8.13,0,0,0,10,4C5.58,4,2,8,2,10c0,1.06,1,2.68,2.62,4L3.29,15.29a1,1,0,1,0,1.41,1.41l1.63-1.63A8.13,8.13,0,0,0,10,16c4.42,0,8-4,8-6C18,8.94,17,7.32,15.38,6ZM4,10c.09-1,2.7-4,6-4a5.89,5.89,0,0,1,2.16.43l-.87.87a3,3,0,0,0-4,4L6,12.55A5.23,5.23,0,0,1,4,10Zm6,4a5.89,5.89,0,0,1-2.16-.43l.87-.87a3,3,0,0,0,4-4L14,7.45A5.23,5.23,0,0,1,16,10C15.91,11,13.3,14,10,14Z'/>
    </g>
  </svg>
);

EyeSlash.defaultProps = defaultProps;

export default EyeSlash
