import { useTranslation } from 'react-i18next';
import { DocumentType } from 'utils/types/documentType';
import styles from './DocumentsTable.module.css';
import { SystemIcon } from '@justworkshr/milo-components';
import { formatDate } from 'utils/helpers/formatDate';
import { TableSortingParams } from 'utils/types/tableTypes';
import SortingMenu from './SortingMenu';

type Props = {
  documents: DocumentType[]
  tableParams: TableSortingParams
  onSort: (value: TableSortingParams) => void
}

const MobileTable = ({ documents, tableParams, onSort }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.mobileViewHeader}>
        <div className={styles.mobileViewLabel}>{t('documents.viewLabel')}</div>
        <div className={styles.sortByContainer}>
          <span className={styles.sortByLabel}>{t('documents.sorting.sortBy')}</span>
          <SortingMenu tableParams={tableParams} onSort={onSort} />
        </div>
      </div>

      <table className={styles.mobileTable}>
        <tbody>
          {
            documents.map((document, index) => (
              <tr key={index}>
                <td className={styles.nameCellContainer}>
                  <SystemIcon
                    iconName='document-pdf'
                    color='disabled'
                    size='small'
                  />
                  <div>
                    <div className={styles.nameCell}>{document.name}</div>
                    <div className={styles.uploadedCell}>{formatDate(document.uploadDate)}</div>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default MobileTable;
