import { AlertType, AlertTypes, useAppAlerts } from 'contexts/AppAlerts';
import { useTranslation } from 'react-i18next';

const useRestApiErrors = () => {
  const { t } = useTranslation();
  const { pushAlerts } = useAppAlerts()

  const usePushApiErrors = (errors: string[]) => {
    const newErrors: AlertType[] = [];
    errors?.forEach((errorCode) => {
      const codeMessage = t(errorCode);
      const message = codeMessage || t('errors.unknown')
      const payload = { message, type: AlertTypes.Error, key: codeMessage }
      newErrors.push(payload)
    });
    pushAlerts(newErrors);
  }

  return { usePushApiErrors }
};

export default useRestApiErrors;
