import { gql } from '@apollo/client';
import { CREATE_MEMBER_DETAIL_MUTATION_NAME } from 'graphql/queries/MemberDetailQueries';
import { CREATE_ADDRESS_MUTATION_NAME } from 'graphql/mutations/AddressMutations';

export const ADD_PROFILE_DETAILS_MUTATION_NAME = 'addProfileDetails';

const ADD_PROFILE_DETAILS = gql`
  mutation(
    $firstName: String!,
    $lastName: String!,
    $preferredName: String!,
    $pronouns: String!,
    $dateOfBirth: ISO8601Date!,
    $phoneNumber: String!,
    $serviceProvided: String!,
    $website: String!,
    $countryCode: CountryCode!,
    $streetLine1: String!,
    $streetLine2: String!,
    $city: String!,
    $state: String!,
    $postalCode: String!
    $nationalId: String
  ) {

    ${CREATE_MEMBER_DETAIL_MUTATION_NAME}(
        input: {
          firstName: $firstName,
          lastName: $lastName,
          preferredName: $preferredName,
          pronouns: $pronouns,
          dateOfBirth: $dateOfBirth,
          phoneNumber: $phoneNumber,
          serviceProvided: $serviceProvided,
          website: $website,
          countryCode: $countryCode,
          nationalId: $nationalId
        }
      ) {
      id
    }

    ${CREATE_ADDRESS_MUTATION_NAME}(
      input: {
        nickname: "Primary"
        countryCode: $countryCode
        streetLine1: $streetLine1
        streetLine2: $streetLine2
        city: $city
        state: $state
        postalCode: $postalCode
      }
    ) {
      id
    }
  }
`;

export default ADD_PROFILE_DETAILS;
