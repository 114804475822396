import { useTranslation } from 'react-i18next';
import { PaystubIcon } from 'components/Icons';
import styles from './PaymentsTable.module.css';

const PaymentsHistoryPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentHistoryPlaceholder}>
      <PaystubIcon />
      <p>
        <b>{t('dashboard.paymentHistoryPlaceholder.title1')}</b>
      </p>
      <p>
        &quot;{t('dashboard.paymentHistoryPlaceholder.sub1')}&quot;
      </p>
      <p>
        - {t('dashboard.paymentHistoryPlaceholder.sub1Author')}
      </p>
    </div>
  );
};

export default PaymentsHistoryPlaceholder;
