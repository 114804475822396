import { ReactNode } from 'react'
import styles from './Menu.module.css'

type Props = {
    children: ReactNode
}

const Menu = ({ children }: Props) => {
  return (
    <div className={styles.menu}>{children}</div>
  )
}

export default Menu
