import React from 'react';

type Props = {
  className?: string;
}

const defaultProps = {
  className: undefined
};

const Eye = ({ className }: Props) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
  >
    <g id='Eye'>
      <path fill='currentColor' d='M10,4C5.58,4,2,8,2,10s3.58,6,8,6,8-4,8-6S14.42,4,10,4Zm0,10c-3.3,0-5.91-3-6-4,.09-1,2.7-4,6-4s5.91,3,6,4C15.91,11,13.3,14,10,14Zm0-7a3,3,0,1,0,3,3A3,3,0,0,0,10,7Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,10,11Z'/>
    </g>
  </svg>
);

Eye.defaultProps = defaultProps;

export default Eye
