import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RightArrowIcon, CheckIcon } from 'components/Icons';
import styles from './InputValidationHelper.module.css';

type Props = {
  validations: {
    i18nKey: string,
    validator: (value: string) => boolean
  }[],
  value: string
};

const InputValidationHelper = ({ validations, value }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {
        validations.map(({ i18nKey, validator }) => {
          const isValid = validator(value);

          return (
            <div
              key={i18nKey}
              className={classNames(
                styles.text,
                {
                  [styles.valid]: isValid,
                  [styles.invalid]: !isValid
                }
              )}
            >
              {
                isValid ? (
                  <CheckIcon className={styles.icon} />
                ) : (
                  <RightArrowIcon className={styles.icon} />
                )
              } {t(i18nKey)}
            </div>
          );
        })
      }
    </div>
  );
};

export default InputValidationHelper;
