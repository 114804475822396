import { gql } from '@apollo/client';

export const CREATE_ADDRESS_MUTATION_NAME = 'createAddress';

export const CREATE_ADDRESS = gql`
  mutation(
    $nickname: String!,
    $country: String!,
    $streetLine1: String!,
    $streetLine2: String,
    $city: String!,
    $state: String,
    $postalCode: String!
  ) {
    ${CREATE_ADDRESS_MUTATION_NAME}(
      input: {
        nickname: $nickname,
        country: $country,
        streetLine1: $streetLine1,
        streetLine2: $streetLine2,
        city: $city,
        state: $state,
        postalCode: $postalCode
      }
    ) {
      id
      nickname
      createdAt
      updatedAt
      details {
        id
        streetLine1
        streetLine2
        city
        state
        countryCode
        postalCode
        createdAt
        updatedAt
        validAt
        invalidAt
      }
    }
  }
`

export const CREATE_ADDRESS_DETAIL_MUTATION_NAME = 'createAddressDetail';

// TODO: Add MUTATION prefix? Move mutations to separate file?
export const ADD_ADDRESS_DETAIL = gql`
  mutation($addressId: ID!, $countryCode: CountryCode!, $streetLine1: String!,
    $streetLine2: String!, $city: String!, $state: String!, $postalCode: String!) {

    ${CREATE_ADDRESS_DETAIL_MUTATION_NAME}(
      input: {
        addressId: $addressId
        countryCode: $countryCode
        streetLine1: $streetLine1
        streetLine2: $streetLine2
        city: $city
        state: $state
        postalCode: $postalCode
      }
    ) {
      id
      addressId
      countryCode
      streetLine1
      streetLine2
      city
      state
      postalCode
    }
  }
`
