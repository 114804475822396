import './ActionFooter.css';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * An **ActionFooter** is a container for calls to action (links and/or buttons) designed to appear
 * at the bottom of a page or section. It is commonly used within a Form to contain submit buttons.
 *
 * All contents are right-aligned by default. Anything in an optional `<aside>` is left-aligned —
 * typically this might be a back button, caption, error message, etc.
 */
const ActionFooter = ({ children, className }) => (
  <footer className={classNames('ActionFooter', className)}>{children}</footer>
);

ActionFooter.propTypes = {
  /** Calls to action (typically Button components) for the right side of the footer */
  children: PropTypes.node.isRequired,

  /** Custom classes to merge with the root class name */
  className: PropTypes.string
};

export default ActionFooter;
