import { REACT_APP_API_HOST } from 'appConstants';

import useLogin from 'hooks/useLogin';

type AuthRequiredType = {
  children: JSX.Element;
  redirect?: string;
};

const AuthRequired = ({ children }: AuthRequiredType) => {
  const { isUserLoggedIn } = useLogin();
  const oktaLoginPath = `${REACT_APP_API_HOST}/v1/_login`

  if (isUserLoggedIn()) {
    return children;
  } else {
    window.location.assign(oktaLoginPath)
    return null
  }
};

export default AuthRequired;
