import React from 'react';

type Props = {
  className?: string;
}

const defaultProps = {
  className: undefined
};

const CaretDown = ({ className }: Props) => (
  <svg className={className} width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path fill='currentColor' d='M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z' />
  </svg>
);

CaretDown.defaultProps = defaultProps;

export default CaretDown
