import React, { useEffect, useRef, useState } from 'react';
import styles from './SliderTabBar.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface SliderTabBarProps {
  floating?: boolean
  tabs: { name: string, value: string }[]
}

const SliderTabBar: React.FC<SliderTabBarProps> = ({
  floating,
  tabs
}) => {
  const [currentTabWidth, setCurrentTabWidth] = useState(0)
  const [currentTabLeft, setCurrentTabLeft] = useState(0)
  const [floatingLeft, setFloatingLeft] = useState(0)

  const location = useLocation();
  const navigate = useNavigate()
  const pathArray = location.pathname.split('/')
  const value = pathArray[pathArray.length - 1]

  const tabBarRef = useRef<HTMLDivElement | null>(null)
  const tabRefs = useRef<{[key: string]: HTMLElement | null}>({});

  const wrapperStyle = floating ? { left: floatingLeft } : {}
  // Max width is a hack to prevent bold text from making the slider too wide when the component first renders
  const sliderStyle = { left: currentTabLeft, width: currentTabWidth, height: floating ? 40 : 24, maxWidth: floating ? 109 : 88 }
  const tabStyle = { height: floating ? 40 : 24 }
  const tabNameStyle = { fontSize: floating ? 16 : 12 }

  const handleTabClick = (tab: string) => {
    navigate(`/dashboard/${tab}`)
  }

  const setCurrentTabStyles = () => {
    const tabRef = tabRefs.current[value] as HTMLElement

    setCurrentTabWidth(tabRef?.clientWidth ?? 0)
    setCurrentTabLeft(tabRef?.offsetLeft ?? 0)
  }

  useEffect(() => {
    setCurrentTabStyles()
  }, [value])

  useEffect(() => {
    const handleResize = () => {
      if (floating) {
        const leftPos = (window.innerWidth / 2) - (tabBarRef?.current ? (tabBarRef?.current?.offsetWidth / 2) : 0)
        setFloatingLeft(leftPos)
      }
      setCurrentTabStyles()
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [value])

  return (
    <div ref={tabBarRef} className={classNames({ [styles.floating]: floating })} style={wrapperStyle}>
      <div className={styles.tabBar}>
        <span className={styles.slider} style={sliderStyle} />

        {tabs.map((tab, index) => (
          <div className={styles.tab} key={index} ref={el => (tabRefs.current[tab.value] = el)} onClick={() => handleTabClick(tab.value)} style={{ ...tabStyle, marginRight: index === tabs.length - 1 ? 0 : 4 }}>
            <span className={styles.tabName} style={{ ...tabNameStyle, fontWeight: tab.value === value ? 'bold' : 'normal' }}>{tab.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderTabBar;
