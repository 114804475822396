import React, { useContext, useEffect, useState } from 'react';
import usePersonaAccess from 'hooks/usePersonaAccess';
import { CheckboxInput, Form, Spinner } from 'assets/milo';
import { useMutation, useLazyQuery } from '@apollo/client';
import { RESUME_INQUIRY } from 'graphql/mutations/ResumeInquiryMutation';
import { QUERY_MEMBER_ONBOARDING } from 'graphql/queries/MemberOnboardingQueries';
import StripeAccountDetails from 'contexts/StripeAccountDetails';
import { Trans, useTranslation } from 'react-i18next';
import styles from './PersonaAccess.module.css';
import FormStyles from 'assets/styles/FormStyles.module.css';
import FormFieldErrorMessage from 'components/Forms/FormFieldErrorMessage';
import { Formik, FormikProps } from 'formik';
import { generateFormValidations } from 'components/Forms/AutoForm';
import { Button } from '@justworkshr/milo-core'

type Props = {
  isOnboarded?: boolean
  onBack?: () => void
  onCompleted: () => void
}

const PersonaAccess = ({ isOnboarded, onCompleted, onBack }: Props) => {
  const [canResume, setCanResume] = useState<boolean>(false);
  const [inquiryId, setInquiryId] = useState<string | undefined>(undefined);
  const [memberId, setMemberId] = useState<string | undefined>(undefined);
  const [stripeAcctId, setStripeAcctId] = useState<string | undefined>();
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const stripeDetails = useContext(StripeAccountDetails);
  stripeDetails?.refetch();
  const { t } = useTranslation();
  const [getMemberOnboarding, { data: memberOnboardingData, loading: loadingMember }] = useLazyQuery(QUERY_MEMBER_ONBOARDING)
  const [resumeInquiry] = useMutation(RESUME_INQUIRY)
  const { openPersonaModal } = usePersonaAccess({ setCanResume, stripeAcctId, onCompleted, setModalLoading, setInquiryId });

  useEffect(() => {
    if (stripeDetails?.stripeAccountDetails?.currentStripeMember?.stripeCustomerId) {
      getMemberOnboarding()
      setStripeAcctId(stripeDetails?.stripeAccountDetails?.currentStripeMember?.stripeCustomerId)
    }
  }, [stripeDetails])

  useEffect(() => {
    const inquiryId = memberOnboardingData?.memberOnboarding?.details?.personaInquiryId;
    const memberId = memberOnboardingData?.memberOnboarding?.memberId;
    setInquiryId(inquiryId);
    setMemberId(memberId);
  }, [memberOnboardingData])

  useEffect(() => {
    if (inquiryId) {
      setCanResume(true);
    }
  }, [inquiryId])

  const openModal = () => {
    setModalLoading(true);
    if (canResume || inquiryId) {
      // make request to Persona API to generate new sessionToken to resume inquiry
      resumeInquiry({
        variables: { inquiryId: inquiryId }
      }).then(({ data }: any) => {
        if (data) {
          const sessionToken = data?.resumePersonaInquiry?.sessionToken;
          openPersonaModal({ inquiryId: inquiryId, sessionToken: sessionToken, memberId: memberId })
        }
      })
    } else {
      openPersonaModal({ inquiryId: inquiryId, memberId: memberId })
    }
  }

  if (loadingMember || !stripeAcctId) { return <Spinner variation='black' /> }

  const tosText = (
    <Trans i18nKey='legal.tos-persona'>
      <a
        href='https://withpersona.com/legal/terms-of-use'
        target='_blank'
        className={styles.legalLink}
      >
        Terms of Use
      </a>
    </Trans>
  )

  const initialValues = {
    termsAgreement: false
  }

  const onSubmit = () => {
    openModal();
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={generateFormValidations([], t, true)}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        errors
      }: FormikProps<typeof initialValues>) => (
        <Form onSubmit={handleSubmit} layout='horizontal'>
          <div className={FormStyles.formFieldContainer}>
            {isOnboarded ? (
              <div className={styles.completedPersona}>
                <Spinner className={styles.spinner} />
                <p>
                  <strong>{t('persona.spinnerTitle')}</strong>
                </p>
                <p>{t('persona.spinnerSubtitle')}</p>
              </div>
            ) : (
              <div>
                <div>
                  <h3 className={styles.cardTitle}>{t('persona.secureIdentityVerification')}</h3>
                  <p className={FormStyles.formSubtitle}>
                    {t('persona.personaSubtitle')}
                  </p>
                </div>

                <div className={FormStyles.tosCheckbox}>
                  <CheckboxInput
                    label={tosText}
                    onBlur={handleBlur}
                    error={!!errors.termsAgreement}
                    name='termsAgreement'
                    checked={values.termsAgreement}
                    onChange={handleChange}
                  />
                  <FormFieldErrorMessage
                    name='termsAgreement'
                    testId='persona-checkbox-error'
                  />
                </div>

                <div className={styles.buttonsContainer}>
                  <Button loading={modalLoading} onClick={handleSubmit}>
                    {canResume || inquiryId ? t('persona.continuePersona') : t('persona.launchPersona')}
                  </Button>
                  <Button mode='secondary' onClick={onBack} variant='outlined'>
                    {t('persona.back')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PersonaAccess
