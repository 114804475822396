import React, { useRef, useState } from 'react';
import styles from './Tooltip.module.css';
import classnames from 'classnames';

type Props = {
  arrowAlignment: 'center' | 'left' | 'right',
  children: React.ReactElement,
  content?: React.ReactElement | number | string,
  ariaLabel?: string
}

type State = {
  top: number | string,
  left: number | string
}

export const Tooltip = ({ children, content, arrowAlignment, ariaLabel }: Props) => {
  const [coords, setCoords] = useState<State>({ top: 0, left: 0 })
  const contentRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<HTMLDivElement>(null)

  const getContentSize = () => {
    return {
      height: contentRef?.current?.offsetHeight || 0,
      width: contentRef?.current?.offsetWidth || 0
    }
  }

  const getLeftAlignment = () => {
    const contentSize = getContentSize();
    const width = targetRef?.current?.offsetWidth || 0

    if (arrowAlignment === 'right') {
      return ((width / 2) - contentSize.width + 20);
    } else {
      return ((width / 2) - (contentSize.width / 2));
    }
  }

  const findCoordinates = () => {
    const top = getContentSize();
    return {
      top: '-' + (top.height + 10) + 'px',
      left: getLeftAlignment() + 'px'
    }
  }

  const centerTooltip = () => {
    const newCoords = findCoordinates()
    setCoords(newCoords)
  }

  if (!content) return children;

  return (
    <span
      className={`${styles.tooltip} ${styles.reactTooltip}`}
      onMouseOver={centerTooltip}
      ref={targetRef}
    >
        <div
          id={ariaLabel}
          className={
            classnames(
              styles.tooltipContent,
              {
                [styles.arrowCenter]: arrowAlignment === 'center',
                [styles.arrowRight]: arrowAlignment === 'right'
              }
            )
          }
          ref={contentRef}
          style={ coords }
        >
          {content}
        </div>
      { children }
      </span>
  )
}

Tooltip.defaultProps = {
  arrowAlignment: 'center',
  ariaLabel: ''
}

export default Tooltip;
