import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';

import useTokenRefresh from 'hooks/useTokenRefresh';

import AppHeader from 'components/AppHeader';

import styles from './LoggedInLayout.module.css';
import { AlertsContainer } from 'components/AlertsContainer';

const LoggedInLayout = () => {
  const { t } = useTranslation();

  useTokenRefresh();

  return (
    <div className={styles.app}>
      <AppHeader />

      <AlertsContainer />

      <main id='main-content'>
        <Outlet />
      </main>

      <footer className={styles.footer}>
        <p>{t('common.copyright', { currentYear: new Date().getFullYear() })}</p>
      </footer>
    </div>
  );
};

export default LoggedInLayout;
