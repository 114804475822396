import { createContext } from 'react';

export type PageTitleContextType = {
  pageTitle: string;
  setPageTitle: (newPageTitle: string) => void
}

const PageTitle = createContext<PageTitleContextType | null>(null);

export default PageTitle;
