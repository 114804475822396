import { Alert, Avatar, Button } from '@justworkshr/milo-core';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import GDPRRequest from './GDPRRequest';
import { QUERY_CURRENT_ADDRESS_DETAILS, GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME } from 'graphql/queries/AddressDetailQueries'
import { QUERY_CURRENT_MEMBER_DETAILS, GET_CURRENT_MEMBER_DETAILS_QUERY_NAME } from 'graphql/queries/MemberDetailQueries'
import { QUERY_CURRENT_USER, GET_CURRENT_USER_QUERY_NAME, QueryCurrentUserResType } from 'graphql/queries/UserQueries';
import styles from './Profile.module.css'
import React, { useEffect, useState } from 'react';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import AddressFormatter from '@shopify/address';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFlagValue } from 'hooks/useFeatureFlags';
import { LD_FLAGS } from 'constants/launchdarklyFlags';
import { COUNTRY_TO_LOCALE, PROFILE_EDIT_URL } from './constants';

const getOptionalValue = (value: string | undefined, defaultText = 'not on file'): React.ReactNode => {
  if (!value || value === 'https://justworks.com') {
    return <span className={styles.notOnFile}>{defaultText}</span>
  }
  return value
}

export const Profile = () => {
  const { t, i18n } = useTranslation();
  const detectedUserLocale = i18n.language;
  const [formattedLocalAddress, setFormattedLocalAddress] = useState(['']);
  const isContractorProfileEditEnabled = useFlagValue(LD_FLAGS.profileEdit);
  const isGDPRRequestEnabled = useFlagValue(LD_FLAGS.gdprRequest);

  const { data: userData } = useQueryWithErrors<QueryCurrentUserResType>(
    GET_CURRENT_USER_QUERY_NAME,
    QUERY_CURRENT_USER
  );

  const { data: addressData } = useQueryWithErrors(
    GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME,
    QUERY_CURRENT_ADDRESS_DETAILS,
    {
      // Ensure we're getting fresh data
      fetchPolicy: 'no-cache'
    }
  );

  const { data: memberData } = useQueryWithErrors(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const countryCode = addressData?.currentAddressDetails?.countryCode;
    const formatToLocalAddress = async () => {
      let localeCode;

      // TODO: Remove this condition when a broader fix is in place
      // This override is due to @shopify/address not supporting various
      // two-letter country codes as acceptable locale codes
      if (COUNTRY_TO_LOCALE[countryCode]) {
        localeCode = COUNTRY_TO_LOCALE[countryCode]
      } else {
        localeCode = detectedUserLocale;
      }

      const addressFormatter = new AddressFormatter(
        localeCode
      );

      const formatted = await addressFormatter.format({
        address1: addressData?.currentAddressDetails?.streetLine1,
        address2: addressData?.currentAddressDetails?.streetLine2,
        city: addressData?.currentAddressDetails?.city,
        province: addressData?.currentAddressDetails?.state,
        zip: addressData?.currentAddressDetails?.postalCode,
        country: countryCode
      });

      setFormattedLocalAddress(formatted);
    };

    if (countryCode) {
      formatToLocalAddress();
    }
  }, [addressData?.currentAddressDetails?.countryCode])

  const onEditButtonClick = () => {
    navigate(PROFILE_EDIT_URL)
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.avatar}>
          <Avatar
            name={`${memberData?.currentMemberDetails?.firstName} ${memberData?.currentMemberDetails?.lastName}`}
            size='lg'
          ></Avatar>
        </div>
        <div className={styles.nameHeader}>
          <h1>{`${memberData?.currentMemberDetails?.firstName} ${memberData?.currentMemberDetails?.lastName}`}</h1>
          <p>{userData?.currentUser?.emailAddress}</p>
        </div>
        {
          isContractorProfileEditEnabled &&
          <div className={styles.editProfile}>
            <Button mode='primary' variant='outlined' onClick={onEditButtonClick}>
              {t('profile.editProfileButton')}
            </Button>
          </div>
        }
      </header>

      <div className={styles.alert}>
        <Alert
          color='info'
          dismissIconDescription='close alert'
          visible
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          children={[
            <span key={0}>
              Email <a href='mailto:contractor-support@justworks.com'>contractor-support@justworks.com</a> if you need to make additional changes.
            </span>
          ]}
        ></Alert>
      </div>

      <div className={styles.personalInfo}>
        <ProfileCard
          title='Personal information'
          legalName={`${memberData?.currentMemberDetails?.firstName} ${memberData?.currentMemberDetails?.lastName}`}
          preferredName={getOptionalValue(memberData?.currentMemberDetails?.preferredName)}
          pronouns={getOptionalValue(memberData?.currentMemberDetails?.pronouns)}
          birthday={
            new Date(memberData?.currentMemberDetails?.dateOfBirth)
              .toLocaleDateString(undefined, {
                timeZone: 'UTC',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
          }
          jobDescription={memberData?.currentMemberDetails?.serviceProvided}
          website={getOptionalValue(memberData?.currentMemberDetails?.website)}
        ></ProfileCard>
      </div>

      <div className={styles.contactInfo}>
        <ProfileCard
          title='Contact information'
          address={formattedLocalAddress
            .filter(addressItem => addressItem) // remove empty elements
            .join('\r\n')
          }
          // TO-DO: Country flag emoji
          phoneNumber={memberData?.currentMemberDetails?.phoneNumber}
        ></ProfileCard>
      </div>

      { isGDPRRequestEnabled && <GDPRRequest /> }
    </div>
  )
};

export default Profile;
