import { useContext } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import { Spinner } from 'assets/milo';
import { sortIncomingPaymentsByDate } from 'utils/helpers/sortPaymentsByDate';
import styles from './IncomingPaymentsTable.module.css';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';

const IncomingPaymentsTable = () => {
  const paymentsContext = useContext(PaymentsContext);

  const incomingPayments = sortIncomingPaymentsByDate(paymentsContext?.paymentsData?.incomingPayments)

  if (!paymentsContext?.paymentsData?.currency) {
    return <Spinner className={styles.spinner} />
  }

  return (
    paymentsContext?.paymentsData?.currency && incomingPayments && incomingPayments.length > 0 ? (
      <div className={styles.paymentsTable}>
        <div className={styles.tableTitle}>Upcoming payments</div>
        <MobileTable payments={incomingPayments} />
        <DesktopTable payments={incomingPayments} currency={paymentsContext.paymentsData.currency} />
      </div>
    ) : null
  )
};

export default IncomingPaymentsTable;
