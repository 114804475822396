import './FormField.css';

import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';

import { LAYOUTS } from 'assets/milo/Form/constants.js';

/**
 * A **FormField** is a single input in a form, which can be decorated with
 * various meta-data and error messages.
 *
 * All unlisted props are passed directly to the underlying `div` element.
 */
export default class FormField extends Component {
  static propTypes = {
    /** The label for the input */
    labelContent: PropTypes.node,

    /**
     * The input component, usually a Milo component with an `Input` suffix.
     * If multiple children are passed, the first is assumed to be the input.
     */
    children: PropTypes.node.isRequired,

    /** Additional helper text describing the input */
    description: PropTypes.node,

    /** If the input is optional - this is only visual, and does not affect validation */
    optional: PropTypes.bool,

    // Error message (should only be set when the field should be in an error state).
    // If `error` is an array, only the first element is used (for use with Rails
    // validation error arrays).
    /**
     * The error message, if the input is in an error state.
     * If `error` is an array, the first element is considered to be the error,
     * to allow passing a Rails validation error array directly.
     */
    error: PropTypes.node,

    /** Override the layout of the surrounding `Form` */
    layout: PropTypes.oneOf([LAYOUTS.HORIZONTAL, LAYOUTS.VERTICAL]),

    /** Custom classes to merge with the root class name */
    className: PropTypes.string,
    id: PropTypes.string
  };

  render() {
    const {
      labelContent,
      children,
      description,
      optional,
      error,
      layout,
      className,
      id,
      ...otherProps
    } = this.props;
    const childrenArray = Children.toArray(children);

    let fieldId;

    if (id) {
      fieldId = id;
    } else {
      fieldId = shortid.generate();
    }

    return (
      <div
        role='group'
        className={classNames(
          'FormField',
          { error, [`layout-${layout}`]: layout },
          className
        )}
        {...otherProps}
      >
        {labelContent && (
          <div className='label-container'>
            <label htmlFor={fieldId}>
              {labelContent}
              {optional && <span className='optional-tag'>Optional</span>}
            </label>
          </div>
        )}
        <div className='field-container'>
          <div className='input-container'>
            {[
              cloneElement(childrenArray[0], { id: fieldId }),
              ...childrenArray.slice(1)
            ]}

            {(() => {
              if (error) {
                const errorMessage = Array.isArray(error) ? error[0] : error;
                if (typeof errorMessage === 'string') {
                  return (
                    <p
                      className='error-message'
                      role='alert'
                      aria-live='assertive'
                    >
                      {errorMessage}
                    </p>
                  );
                } else {
                  return (
                    <div
                      className='error-message'
                      role='alert'
                      aria-live='assertive'
                    >
                      {errorMessage}
                    </div>
                  );
                }
              }
            })()}
          </div>

          {(() => {
            if (description) {
              if (typeof description === 'string') {
                return <p className='description'>{description}</p>;
              } else {
                return <div className='description'>{description}</div>;
              }
            }
          })()}
        </div>
      </div>
    );
  }
}
