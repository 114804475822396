import { useContext, useState } from 'react';
import { DocumentsContext } from 'contexts/Documents';
import DocumentsPlaceholder from './DocumentsPlaceholder';
import styles from './DocumentsTable.module.css';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';
import { DEFAULT_DOCUMENTS_TABLE_PARAMS } from 'constants/documents';
import { sort } from 'utils/helpers/documents';

const DocumentsTable = () => {
  const [tableParams, setTableParams] = useState(DEFAULT_DOCUMENTS_TABLE_PARAMS)

  const documentsContext = useContext(DocumentsContext);

  const documents = sort(tableParams, documentsContext?.documentsData.documents)

  return (
    documents && documents.length > 0 ? (
      <div className={styles.documentsTable}>
        <MobileTable documents={documents} tableParams={tableParams} onSort={setTableParams} />
        <DesktopTable documents={documents} tableParams={tableParams} onSort={setTableParams} />
      </div>
    ) : (
      <DocumentsPlaceholder />
    )
  )
};

export default DocumentsTable;
