import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { Spinner } from 'assets/milo';

import useLogin from 'hooks/useLogin';
const PaymentInvitation = () => {
  const { isUserLoggedIn } = useLogin();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (!isUserLoggedIn() && id) {
      navigate(
        {
          pathname: '/account/create',
          search: `?${createSearchParams({
            payment_invitation: id
          })}`
        },
        { replace: true }
      );
    } else {
      navigate('/', { replace: true });
    }
  }, [isUserLoggedIn, id]);

  return <Spinner variation='black' />
};

export default PaymentInvitation;
