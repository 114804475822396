export const REACT_APP_ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV;
export const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:4000';
export const REACT_APP_DATADOG_APPLICATION_ID = '641102e2-8cbf-461e-ac8d-851c3fbcd1d9';
export const REACT_APP_DATADOG_CLIENT_TOKEN = 'puba736e1f89c8242619881cd8d846f3aa0';
export const REACT_APP_PERSONA_ENV_ID = process.env.REACT_APP_PERSONA_ENV_ID || 'env_ED6iUtAp9uxCCqwARuGEm7Cr';
export const REACT_APP_PERSONA_TEMPLATE_ID = 'itmpl_9BhvsVDDcvq6UvvkxFCkgMuu';
export const REACT_APP_LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '65776900c2f047109b485bb8';
export const TOKEN_EXP_KEY = 'tokenExp';
export const ACCESS_TOKEN_EXPIRATION_KEY = 'access_expiration'
export const OKTA_LOGIN_PATH = `${REACT_APP_API_HOST}/v1/_login`
