import { gql } from '@apollo/client';

const CURRENT_STRIPE_MEMBER_DETAILS = 'currentStripeMemberDetails';
const CURRENT_STRIPE_MEMBER = 'currentStripeMember';

export type CurrentStripeMemberDetailsResType = {
  currentStripeMember: {
    stripeCustomerId: string,
  },
  currentStripeMemberDetails: {
    id: string,
    accountRequirements?: Record<string, unknown>,
    personRequirements?: Record<string, unknown>,
    verificationDetailsCode?: string,
    verificationStatus?: string,
    disabledReason?: string
  }
}

export const QUERY_CURRENT_STRIPE_MEMBER_DETAILS = gql`
  {
    ${CURRENT_STRIPE_MEMBER} {
      stripeCustomerId
    }
    ${CURRENT_STRIPE_MEMBER_DETAILS} {
      id
      accountRequirements
      personRequirements
      verificationDetailsCode
      verificationStatus
      disabledReason
    }
  }
`

export const CREATE_MEMBER_MUTATION_NAME = 'createMember';

export const ADD_MEMBER = gql`
  mutation($emailAddress: String!, $username: String!, $password: String!) {
    ${CREATE_MEMBER_MUTATION_NAME}(
        input: {
          emailAddress: $emailAddress,
          username: $username,
          password: $password
        }
      ) {
      id
      userId
    }
  }
`
