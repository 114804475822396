import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const DEFAULT_NS = 'translations';

export function initializei18n() {
  i18next
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(resourcesToBackend((
      language: string,
      namespace: string,
      callback: (error: Error | null, resources: Record<string, string> | null) => void
    ) => {
      import(`./locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources)
        })
        .catch((error) => {
          callback(error, null)
        })
    }))
    .init({
      fallbackLng: 'en-US',
      defaultNS: DEFAULT_NS,
      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      react: {
        useSuspense: true
      },
      returnNull: false
    });
}

export default i18next;
