import { SORT } from './table';

export const DOCUMENTS_TABLE_KEYS = {
  NAME: 'name',
  UPLOADED: 'uploadDate'
};

export const DEFAULT_DOCUMENTS_TABLE_PARAMS = {
  sortBy: DOCUMENTS_TABLE_KEYS.UPLOADED,
  sortDirection: SORT.DESC
};
