import { useState, useEffect } from 'react';
import { post } from 'utils/helpers/api';
import useLogin from './useLogin';
import { OKTA_LOGIN_PATH } from 'appConstants';

type UseTokenRefreshType = {
  isRefreshing: boolean;
  error: string | undefined;
};

const useTokenRefresh = (): UseTokenRefreshType => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { isUserLoggedIn, accessExpiration } = useLogin();

  let isLoggedIn = isUserLoggedIn();
  let expiration = accessExpiration;
  const [timeoutId, setTimeoutId] = useState<number>(0);

  useEffect(() => {
    if (
      isLoggedIn
    ) {
      const refreshAccessToken = () => {
        if (!isRefreshing) {
          setIsRefreshing(true);
          post('/v1/refresh')
            .then((data) => {
              if (!data.ok) {
                window.location.assign(OKTA_LOGIN_PATH);
                return;
              }

              const { isUserLoggedIn, accessExpiration } = useLogin();
              isLoggedIn = isUserLoggedIn();
              expiration = accessExpiration;

              // Refresh slightly (5 seconds) before the token expires
              const timeTilRefresh = expiration - Date.now() - 5000;
              const newTimeoutId = setTimeout(
                refreshAccessToken,
                timeTilRefresh
              ) as unknown as number;
              setTimeoutId((oldId) => {
                if (oldId !== newTimeoutId) {
                  clearTimeout(oldId);
                }
                return newTimeoutId;
              });
            })
            .catch((err: Error) => setError(err.message))
            .finally(() => setIsRefreshing(false));
        }
      };

      const timeTilRefresh = expiration - Date.now() - 5000;
      const newTimeoutId = setTimeout(
        refreshAccessToken,
        timeTilRefresh
      ) as unknown as number;
      setTimeoutId((oldId) => {
        if (oldId !== newTimeoutId) {
          clearTimeout(oldId);
        }
        return newTimeoutId;
      });
    }

    return () => clearTimeout(timeoutId);
  }, []);

  return { isRefreshing, error };
};

export default useTokenRefresh;
