import Toast from 'components/Toast';
import { ToastType } from 'contexts/Toast';
import styles from './ToastList.module.css'

type ToastListProps = {
  toasts: ToastType[],
  clearToast: (id: number) => void
}

const ToastList = ({ toasts, clearToast }: ToastListProps) => {
  return (
    <div className={styles.toastList}>
      {toasts.map((toast) => <Toast key={toast.id} clear={() => clearToast(toast.id)} type={toast.type}>{toast.content}</Toast>)}
    </div>
  )
}

export default ToastList;
