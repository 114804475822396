import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';

type ExpectedExtensionsType = {
  code?: string | number,
  argumentName?: string,
  argumentType?: string,
  inputObjectType?: string
  errors: FormikErrors<Record<string, string>>,
  formErrors: FormikErrors<Record<string, string>>
}

const useMutationFormErrors = (gqlErrors?: ApolloError) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<FormikErrors<Record<string, string>>>({});

  useEffect(() => {
    // Error keys can be nested like: { institutionGroup: { transitNumber: errorCode }}
    // so we use DFS to recusively go through each object at each level to reach the translatable properties
    function translateErrors(errors: any) {
      if (!errors) {
        return
      }

      Object.keys(errors).forEach((key: string) => {
        if (typeof errors[key] === 'string') {
          errors[key] = t(errors[key] as string)
        } else if (typeof errors[key] === 'object') {
          translateErrors(errors[key])
        }
      })
    }

    let newErrors: FormikErrors<Record<string, string>> = {};

    gqlErrors?.graphQLErrors?.forEach((gqlError) => {
      const extensions = gqlError.extensions as ExpectedExtensionsType;
      newErrors = extensions.formErrors
      translateErrors(newErrors)
    });

    setErrors(newErrors);
  }, [gqlErrors]);

  return errors
};

export default useMutationFormErrors;
