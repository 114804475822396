export const MODES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  FRAMELESS: 'frameless',
  TEXT: 'text'
};

export const SIZES = {
  DEFAULT: 'default',
  LARGE: 'large',
  SMALL: 'small'
};

export const COLORS = {
  BLUE: 'blue',
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray'
};

export const TYPES = {
  LINK: 'link',
  RESET: 'reset',
  SUBMIT: 'submit',
  BUTTON: 'button',
  REACT_ROUTER_LINK: 'react-router-link'
};
