import './Form.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LAYOUTS, SPACING, METHODS } from './constants';

/**
 * A **Form** contains interactive inputs, used to collect data from the
 * user and submit it to the server.
 *
 * All unlisted props are passed directly to the underlying `form` element.
 * See the [React form docs](https://facebook.github.io/react/docs/forms.html) for more info about what props are supported.
 */
export default class Form extends Component {
  static propTypes = {
    /** The contents of the form, typically `FormField` and various input components */
    children: PropTypes.node.isRequired,

    /** Custom classes to merge with the root class name */
    className: PropTypes.string,

    /** The HTTP method used to submit the form */
    method: PropTypes.oneOf(Object.values(METHODS)),

    /** The layout of the form, which primarily affects the location of field labels */
    layout: PropTypes.oneOf([LAYOUTS.HORIZONTAL, LAYOUTS.VERTICAL]),

    /** The spacing between fields in the form */
    spacing: PropTypes.oneOf([SPACING.MEDIUM, SPACING.LARGE]),

    /** If the layout is dynamic, preventing the form from setting explicit widths on any of its inputs */
    dynamicLayout: PropTypes.bool
  };

  static defaultProps = {
    layout: LAYOUTS.HORIZONTAL,
    spacing: SPACING.MEDIUM,
    method: METHODS.GET
  };

  render() {
    const {
      children,
      layout,
      spacing,
      className,
      method,
      dynamicLayout,
      ...otherProps
    } = this.props;

    const needsMethodAttribute = [
      METHODS.PATCH,
      METHODS.PUT,
      METHODS.DELETE
    ].includes(method);
    const actualMethod = needsMethodAttribute ? METHODS.POST : method;

    return (
      <form
        method={actualMethod}
        className={classNames(
          'Form',
          className,
          `layout-${layout}`,
          `spacing-${spacing}`,
          { 'dynamic-layout': dynamicLayout }
        )}
        {...otherProps}
      >
        {needsMethodAttribute && (
          <input type='hidden' name='_method' value={method} />
        )}
        <section>{children}</section>
      </form>
    );
  }
}
