import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { AlertTypes, useAppAlerts } from 'contexts/AppAlerts';
import { useTranslation } from 'react-i18next';

const useGraphqlErrors = (operationName: string, error?: ApolloError) => {
  const { t } = useTranslation();
  const { pushAlerts } = useAppAlerts()

  useEffect(() => {
    if (error) {
      error.graphQLErrors?.forEach((gqlError) => {
        if (gqlError?.extensions?.formErrors) {
          return
        }

        const code = (gqlError?.extensions?.code as string | number) || 500;

        const i18nKey = `errors.${operationName}.${code}`;
        const codeMessage = t(i18nKey);
        const message = codeMessage !== i18nKey ? codeMessage : t('errors.unknown')
        const alert = { message, type: AlertTypes.Error }
        pushAlerts([alert]);
        window.scrollTo(0, 0)
      });

      if (error.networkError) {
        const message = t('errors.networkError')
        const alert = { message, type: AlertTypes.Error }
        pushAlerts([alert]);
      }
    }
  }, [operationName, error]);
};

export default useGraphqlErrors;
