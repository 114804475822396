import { CreateTypes } from 'canvas-confetti';
import ReactCanvasConfetti from 'react-canvas-confetti';
import ConfettiStyles from './ConfettiStyles.module.css';

type ConfettiTypeProps = {
  className?: string;
  refConfetti?: HTMLCanvasElement;
  getInstance?: (confetti: CreateTypes | null) => void;
};

const Confetti = (props: ConfettiTypeProps) => {
  const { getInstance } = props;
  return (
    <ReactCanvasConfetti
      refConfetti={getInstance}
      className={ConfettiStyles.customConfetti}
    />
  );
};

export default Confetti;
