import { ReactElement } from 'react';
import styles from './FormFieldWrapper.module.css';
import classNames from 'classnames';

type Props = {
  children: ReactElement,
  className?: string,
  group?: boolean
}

const FormFieldWrapper = ({ children, className, group }: Props) => {
  return (
    <div className={classNames(styles.container, className, group ? styles.group : '')}>
      {children}
    </div>
  )
};

export default FormFieldWrapper;
