export const TABS = [
  {
    value: 'payments',
    name: 'Payments'
  },
  {
    value: 'documents',
    name: 'Documents'
  }
]
