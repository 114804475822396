import { gql } from '@apollo/client';

import {
  FieldTypes
} from 'utils/types/formTypes';

type BankFieldType = {
  type: FieldTypes;
  name: string;
  label: string;
  description: string;
  fields: [BankFieldType];
  validations: any;
}

export type BankAccountFormType = {
  header: string;
  subheader: string;
  fields: [BankFieldType];
  initialValues: any;
}

export type QueryBankAccountFormResType = {
  bankAccountForm: BankAccountFormType
}

export const GET_BANK_ACCOUNT_FORM_QUERY_NAME = 'bankAccountForm';

export const QUERY_BANK_ACCOUNT_FORM = gql`
  query ${GET_BANK_ACCOUNT_FORM_QUERY_NAME} ($countryCode: String!) {
    ${GET_BANK_ACCOUNT_FORM_QUERY_NAME} (countryCode: $countryCode) {
      header
      subheader
      fields
      initialValues
    }
  }
`
