import { gql } from '@apollo/client';

export const GET_CURRENT_USER_QUERY_NAME = 'getCurrentUser';

export type QueryCurrentUserResType = {
  currentUser: {
    id?: string;
    username?: string;
    emailAddress?: string;
  }
}

export const QUERY_CURRENT_USER = gql`
  query ${GET_CURRENT_USER_QUERY_NAME} {
    currentUser {
      id
      username
      emailAddress
    }
  }
`
