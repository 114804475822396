import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import usePageTitle from 'hooks/usePageTitle';
import styles from './AdminHome.module.css';

export default function Home() {
  const { t } = useTranslation();
  usePageTitle(t('title'));

  return (
    <div className={styles.content}>
      <h1>{t('admin.title')}</h1>

      <p>
        {t('admin.body')}
      </p>

      <p>
        <Link to='system-details'>System details</Link>
      </p>
    </div>
  )
}
