import './Spinner.css';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * An animated spinner to represent a loading state.
 */
const Spinner = ({ className, variation }) => (
  <span className={classNames('Spinner', className, { black: variation === 'black' })}>
    <span className='bounce1'></span>
    <span className='bounce2'></span>
    <span className='bounce3'></span>
  </span>
);

Spinner.propTypes = {
  /** Custom classes to merge with the root class name */
  className: PropTypes.string,
  variation: PropTypes.oneOf(['black', 'white'])
};

Spinner.defaultProps = {
  variation: 'white'
}

export default Spinner;
