import React, { useEffect, useState } from 'react';
import styles from './ProfileAvatar.module.css';
import { Box } from '@justworkshr/milo-components';
import { Avatar } from '@justworkshr/milo-core';
import { FileInput } from '@justworkshr/milo-form';

const ProfileAvatar: React.FC = () => {
  // TODO: Make API request to retrieve profile avatar, or it may be passed
  // into here as a prop from parent component

  const [photoUrl, setPhotoUrl] = useState<string | undefined>(undefined);

  const fullName = 'Awesome Name'; // TODO: Pull from profile object when loaded

  const handleFileAdd = (file: File | File[]) => {
    // Check if file is an array and select the first file, otherwise use the file directly.
    const selectedFile = Array.isArray(file) ? file[0] : file;

    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      setPhotoUrl(fileUrl);
    }
  };

  const handleFileRemove = () => {
    if (photoUrl) {
      setPhotoUrl(undefined);
    }
  }

  useEffect(() => {
    // Memory cleanup of URL object when component unmounts or photoUrl changes
    return () => {
      if (photoUrl) {
        URL.revokeObjectURL(photoUrl);
      }
    };
  }, [photoUrl]);

  return (
    <div className={styles.profileCard}>
      <Box
        backgroundColor='neutral'
        border={{
          borderColor: 'neutral',
          borderRadius: 'md',
          borderWidth: 'sm'
        }}
        padding='xl'
      >
        <div className={styles.columns}>
          <div className={styles.column}>
            <h3>Profile picture</h3>
            <p>Your new profile picture must be a PNG, JPEG, or GIF.</p>

            <FileInput
              accept='image/png, image/jpeg, image/gif'
              multiple={false}
              onFileAdd={(file) => {
                if (file) {
                  handleFileAdd(file)
                }
              }}
              onFileRemove={() => {
                handleFileRemove()
              }}
              style={{ marginTop: '20px' }}
              previouslyUploadedFiles={[]} // TODO: Add path to previously uploaded avatar
            />
          </div>
          <div className={styles.column}>
            <h3>Preview</h3>
            <div className={styles.avatarPreviewContainer}>
              <Avatar
                name={fullName}
                size='xl'
                photoUrl={photoUrl}
              ></Avatar>
              <Avatar
                name={fullName}
                size='sm'
                photoUrl={photoUrl}
              ></Avatar>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ProfileAvatar;
