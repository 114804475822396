import React from 'react';

type Props = {
  className?: string;
}

const defaultProps = {
  className: undefined
};

const RightArrow = ({ className }: Props) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
  >
    <g id='Arrow'>
      <path fill='currentColor' d='M15.71,9.29l-4-4a1,1,0,0,0-1.41,1.41L12.59,9H5a1,1,0,0,0,0,2h7.59l-2.29,2.29a1,1,0,1,0,1.41,1.41l4-4A1,1,0,0,0,15.71,9.29Z'/>
    </g>
  </svg>
);

RightArrow.defaultProps = defaultProps;

export default RightArrow
