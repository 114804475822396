import Cookie from 'js-cookie';
import { ACCESS_TOKEN_EXPIRATION_KEY } from 'appConstants';

export type UseLoginType = {
  isUserLoggedIn: () => boolean;
  accessExpiration: number
};

const useLogin = (): UseLoginType => {
  const accessTokenExpirationSeconds = Cookie.get(ACCESS_TOKEN_EXPIRATION_KEY)
  const accessExpiration = (Number(accessTokenExpirationSeconds) * 1000)

  const isUserLoggedIn = () => {
    if (!accessTokenExpirationSeconds) {
      return false;
    }

    // Date.now() returns milliseconds, so we need to convert the cookie value to milliseconds
    const accessValid = accessExpiration > Date.now()

    return accessValid
  }

  return { isUserLoggedIn, accessExpiration };
};

export default useLogin;
