import './Button.css';
import { Link } from 'react-router-dom';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { COLORS, MODES, TYPES, SIZES } from './constants';

import Spinner from 'assets/milo/Spinner';

/**
 * A **Button** triggers any call to action that does not simply navigate to another page.
 * In other words, any click that causes something in the app to change (whether on the page or in the database)
 * should be triggered by a button.
 */
export default class Button extends Component {
  static propTypes = {
    /** Custom classes to merge with the root class name */
    className: PropTypes.string,

    /** The visual mode of the button */
    mode: PropTypes.oneOf([
      /** Primary buttons has a background color fill */
      MODES.PRIMARY,
      /** Secondary buttons have a clear background, with a border color that matches text color */
      MODES.SECONDARY,
      /** Frameless buttons have no background or border */
      MODES.FRAMELESS,
      /** Text buttons have colored text, but will inherit all other text styling */
      MODES.TEXT
    ]),

    /** The color of the button */
    color: PropTypes.oneOf([
      COLORS.BLUE,
      COLORS.RED,
      COLORS.GREEN,
      COLORS.GRAY
    ]),

    /** The type of the button, which determines the underlying HTML element */
    type: PropTypes.oneOf([
      /** `<a>` element */
      TYPES.LINK,
      /** `<button type="reset">` element */
      TYPES.RESET,
      /** `<button type="submit">` element */
      TYPES.SUBMIT,
      /** `<button type="button">` element */
      TYPES.BUTTON,
      /** react-router `<Link>` (which in turn renders an `<a>` element) */
      TYPES.REACT_ROUTER_LINK
    ]),

    /** The size of the button */
    size: PropTypes.oneOf([SIZES.DEFAULT, SIZES.LARGE, SIZES.SMALL]),

    /** The contents of the button, ideally text */
    children: PropTypes.any.isRequired,

    /** The location a `LINK` type button should navigate to - required for a `LINK` type, not supported for other types */
    href: PropTypes.string,

    /**
     * The location a `REACT_ROUTER_LINK` type button should navigate to - required for a `REACT_ROUTER_LINK` type, not supported for other types.
     * See https://github.com/ReactTraining/react-router/blob/master/modules/Link.js#L51 for more details.
     */
    // eslint-disable-next-line id-length
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func
    ]),

    /** If the button is loading. A loading button is also disabled. */
    loading: PropTypes.bool,

    /** If the button is disabled */
    disabled: PropTypes.bool,

    /** Handle clicks to the button */
    onClick: PropTypes.func,

    /** The HTML `target` attribute - only supported on `LINK` types */
    target: PropTypes.string,

    /** An decorative icon to show before or after the button text */
    icon: PropTypes.shape({
      /** The actual icon to show, typically an svg */
      figure: PropTypes.node.isRequired,

      /** If the icon should appear after the text, instead of before it */
      afterText: PropTypes.bool,

      /** If the button should visually only show the icon, announcing children's contents only for screen readers */
      iconOnly: PropTypes.bool
    })
  };

  static defaultProps = {
    mode: MODES.PRIMARY,
    color: COLORS.BLUE,
    disabled: false,
    loading: false,
    type: TYPES.SUBMIT,
    size: SIZES.DEFAULT
  };

  handleClick = event => {
    if (this.props.disabled) {
      event.preventDefault();
      return false;
    } else if (this.props.onClick) {
      return this.props.onClick(event);
    } else {
      return true;
    }
  };

  render() {
    const {
      className,
      children,
      loading,
      size,
      mode,
      color,
      href,
      to, // eslint-disable-line id-length
      type,
      target,
      disabled,
      icon,
      ...otherProps
    } = this.props;
    const childrenContent =
      icon && icon.iconOnly ? (
        <span className='hidden'>{children}</span>
      ) : (
        children
      );
    const content = (
      <div className='Button__content'>
        {icon && !icon.afterText && (
          <figure className='Button__icon Button__icon--before-text'>
            {icon.figure}
          </figure>
        )}
        {childrenContent}
        {icon && icon.afterText && (
          <figure className='Button__icon Button__icon--after-text'>
            {icon.figure}
          </figure>
        )}
      </div>
    );
    const classes = classNames(
      'Button',
      className,
      mode,
      type,
      size,
      color,
      { disabled },
      { loading }
    );

    // only the loading state triggers the Spinner
    let spinner;

    if (loading) {
      spinner = <Spinner />;
    }

    switch (type) {
      case TYPES.LINK:
        if ('to' in this.props) {
          throw new Error(
            '<Button> must be TYPES.REACT_ROUTER_LINK for `to` prop to work.'
          );
        }

        return (
          <a
            role='button'
            href={href || '#'}
            aria-disabled={loading || disabled}
            onClick={this.handleClick}
            className={classes}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : ''}
            aria-label={loading ? 'Loading' : undefined}
          >
            {content}
            {spinner}
          </a>
        );

      case TYPES.REACT_ROUTER_LINK:
        return (
          <Link
            to={to || '#'}
            aria-disabled={loading || disabled}
            className={classes}
            onClick={this.handleClick}
            aria-label={loading ? 'Loading' : undefined}
          >
            {content}
            {spinner}
          </Link>
        );
      default:
        if ('to' in this.props) {
          throw new Error(
            '<Button> must be TYPES.REACT_ROUTER_LINK for `to` prop to work.'
          );
        }

        if ('target' in this.props) {
          throw new Error(
            '<Button> must be TYPES.LINK for `target` prop to work.'
          );
        }

        if ('href' in this.props) {
          throw new Error(
            '<Button> must be TYPES.LINK or TYPES.REACT_ROUTER_LINK for `href` prop to work.'
          );
        }

        return (
          <button
            disabled={loading || disabled}
            type={type}
            onClick={this.handleClick}
            className={classes}
            aria-label={loading ? 'Loading' : undefined}
            {...otherProps}
          >
            {content}
            {spinner}
          </button>
        );
    }
  }
}
