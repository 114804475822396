import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
  ApolloProvider
} from '@apollo/client';
import reportWebVitals from 'reportWebVitals';
import 'index.css';
import 'assets/stylesheets/colors/foundations.css';
import 'assets/stylesheets/colors/aliases.css';
import 'assets/stylesheets/colors/components.css';
import App from 'App';
import client from './ApolloClient';
import { initializeDataDog } from './datadog-rum';
import { initializei18n } from './i18n';

initializei18n();
initializeDataDog();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!)

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
