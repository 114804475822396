import { useState, createContext, Dispatch, SetStateAction } from 'react';

import { DocumentType } from 'utils/types/documentType';

type DocumentsDataType = {
  documents?: DocumentType[],
  setDocumentsData?: Dispatch<SetStateAction<DocumentsDataType>>
}

export type DocumentsContextType = {
  documentsData: DocumentsDataType
  setDocumentsData: Dispatch<SetStateAction<DocumentsDataType>>
}

export const DocumentsContext = createContext<DocumentsContextType | null>(null);

export const DocumentsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [documentsData, setDocumentsData] = useState<DocumentsDataType>({
    documents: []
  });

  return (
    <DocumentsContext.Provider value={{ documentsData, setDocumentsData }}>
      {children}
    </DocumentsContext.Provider>
  );
};
