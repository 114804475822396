/** This FieldGroup component utilizes Milo 3 components. Replace this with a Milo import
 * when FieldGroup is added to Milo 3. **/
import React from 'react';
import classNames from 'classnames';
import styles from './FieldGroup.module.css';

interface Props {
  className?: string;
  children: any;
}

const FieldGroup : React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.FieldGroup, className)} role='group'>
    {children}
  </div>
);

export default FieldGroup;
