import React from 'react'
import { SystemIcon } from '@justworkshr/milo-components';
import { SORT } from 'constants/table';

interface Props {
  sortBy: string;
  sortKey: string;
  sortDirection: string;
}

export const SortIcon = ({
  sortBy,
  sortKey,
  sortDirection
}: Props) => {
  if (sortBy === sortKey) {
    if (sortDirection === SORT.ASC) {
      return <SystemIcon iconName='arrow-up' />;
    } else if (sortDirection === SORT.DESC) {
      return <SystemIcon iconName='arrow-down' />;
    }
  }

  return <SystemIcon iconName='arrows-vertical' />;
};

export default SortIcon;
