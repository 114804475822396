import { ChangeEvent, FocusEvent, useState } from 'react';
import { FormField, TextInput } from 'assets/milo';
import { useTranslation } from 'react-i18next';
import FormFieldErrorMessage from 'components/Forms/FormFieldErrorMessage';
import { EyeIcon, EyeSlashIcon } from 'components/Icons';
import styles from './PasswordInput.module.css';
import InputValidationHelper from 'components/Inputs/InputValidationHelper';
import { passwordValidationHelpers } from 'utils/regex/password';
import Tooltip from 'components/Tooltip';

type Props = {
  onChange: (e: ChangeEvent<any>) => void,
  onBlur: (e: FocusEvent<any, Element>) => void,
  value: string,
  withHelper?: boolean
}

const defaultProps = {
  withHelper: false
}

const PasswordInput = ({ onChange, onBlur, value, withHelper }: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showHelper, setShowHelper] = useState<boolean>(false);

  const toggleVisibility = () => setIsVisible((prevState) => !prevState);

  const handleFocus = () => {
    if (withHelper) setShowHelper(true);
  }

  const handleBlur = (event: FocusEvent<any, Element>) => {
    if (withHelper) setShowHelper(false);
    onBlur(event);
  }

  let icon;
  let tooltipContent;

  if (isVisible) {
    icon = <EyeSlashIcon className={styles.visibilityToggle} />;
    tooltipContent = t('member.hidePassword');
  } else {
    icon = <EyeIcon className={styles.visibilityToggle} />;
    tooltipContent = t('member.showPassword')
  }

  return (
    <FormField labelContent={<>{t('member.password')}</>}>
      <div className={styles.container}>
        <TextInput
          className={styles.passwordInput}
          id='password'
          name='password'
          type={isVisible ? 'text' : 'password'}
          data-testid='password-input'
          placeholder={t('member.password')}
          onChange={onChange}
          onBlur={handleBlur}
          value={value}
          onFocus={handleFocus}
        />
        <Tooltip content={tooltipContent} ariaLabel={'eye-icon-button'}>
          <div className={styles.eyeIconContainer}>
            <button aria-pressed={isVisible} aria-labelledby={'eye-icon-button'} type={'button'} onClick={toggleVisibility} className={styles.eyeIcon}>
              {icon}
            </button>
          </div>
        </Tooltip>
      </div>
      {
        withHelper && showHelper ? (
          <InputValidationHelper
            validations={passwordValidationHelpers}
            value={value}
          />
        ) : (
          <FormFieldErrorMessage name='password' testId='password-error' />
        )
      }
    </FormField>
  )
};

PasswordInput.defaultProps = defaultProps;

export default PasswordInput;
