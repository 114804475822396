export const TYPES = {
  SUCCESS: 'success',
  NOTICE: 'notice',
  ERROR: 'error',
  WARNING: 'warning'
};

export const ALIGNMENTS = {
  LEFT: 'left',
  CENTER: 'center'
};
