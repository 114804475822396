import { DocumentType } from 'utils/types/documentType';
import { DOCUMENTS_TABLE_KEYS } from 'constants/documents';
import { SORT } from 'constants/table';
import { TableSortingParams } from 'utils/types/tableTypes';

export const sort = (tableParams: TableSortingParams, documents?: DocumentType[]): DocumentType[] => {
  const toSort = [...(documents || [])];

  switch (tableParams.sortBy) {
    case DOCUMENTS_TABLE_KEYS.UPLOADED:
      return toSort.sort((a, b) => {
        let value = 0;
        const date1 = new Date(a.uploadDate);
        const date2 = new Date(b.uploadDate);
        if (date1 > date2) {
          value = 1;
        } else if (date1 < date2) {
          value = -1;
        }
        return tableParams.sortDirection === SORT.ASC ? value : -value;
      })
    case DOCUMENTS_TABLE_KEYS.NAME:
    default:
      return toSort.sort((a, b) => {
        let value = 0;
        if (a.name > b.name) {
          value = 1;
        } else if (a.name < b.name) {
          value = -1;
        }
        return tableParams.sortDirection === SORT.ASC ? value : -value;
      })
  }
};
