import { Link } from 'react-router-dom';

import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <main className={styles.main}>
      <section>
        <h1>404 Not found</h1>
        <p><Link className={styles.link} to='/'>Home</Link></p>
      </section>
    </main>
  );
}

export default NotFound;
