import { gql } from '@apollo/client';

export const GET_PAYMENT_INVITATION_BY_ID = 'getPaymentInvitationById';

export const QUERY_PAYMENT_INVITATION_BY_ID = (id: String) => gql`
  query ${GET_PAYMENT_INVITATION_BY_ID} {
    getPaymentInvitationById(id: "${id}") {
      id
      companyId
      token
      emailAddress
      invitedAsType

      details {
        id
        paymentInvitationId
        status
        message
        validAt
        invalidAt
        countryCode
      }
    }
  }
`

export const GET_PAYMENT_INVITATION_BY_CURRENT_USER_ID = 'getPaymentInvitationByCurrentUserId';

export const QUERY_PAYMENT_INVITATION_BY_CURRENT_USER_ID = gql`
  query ${GET_PAYMENT_INVITATION_BY_CURRENT_USER_ID} {
    getPaymentInvitationByCurrentUserId {
      id
      companyId
      token
      emailAddress
      invitedAsType

      details {
        id
        paymentInvitationId
        status
        message
        validAt
        invalidAt
        countryCode
      }
    }
  }
`
