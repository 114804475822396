import { PageHeader, Button, SkeletonText, Dialog } from '@justworkshr/milo-core';
// import { QUERY_CURRENT_MEMBER_DETAILS, GET_CURRENT_MEMBER_DETAILS_QUERY_NAME } from 'graphql/queries/MemberDetailQueries'
import React, { useEffect, useState } from 'react';
import styles from './GDPRRequest.module.css'
// import useQueryWithErrors from 'hooks/useQueryWithErrors';
import { Trans, useTranslation } from 'react-i18next';
import { REACT_APP_API_HOST } from 'appConstants';

export const GDPRRequest = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isDeleteRequestModalOpen, setIsDeleteRequestModalOpen] = useState(false);
  const [isDeleteRequestSubmitted, setIsDeleteRequestSubmitted] = useState(false);

  // TODO: Update with actual query when it's ready
  // NOTE: Is using refetch() enough, or do we need useLazyQuery?
  // NOTE: Replace below with appropriate queries/mutations for GDPR
  // const { data: memberData } = useQueryWithErrors(
  //   GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
  //   QUERY_CURRENT_MEMBER_DETAILS,
  //   {
  //     fetchPolicy: 'no-cache'
  //   }
  // )

  const sendDataDeletionRequest = async() => {
    try {
      const response = await fetch(`${REACT_APP_API_HOST}/v1/gdpr/request_data_deletion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Network response not ok')
      }
      console.log('Confirmation email sent')
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // This simulates loading the GDPR state/status
  useEffect(() => {
    if (!isDeleteRequestModalOpen && isDeleteRequestSubmitted) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  }, [isDeleteRequestModalOpen])

  const onDeleteRequest = async () => {
    // TODO: run query to submit delete request
    setSubmitLoading(true);

    setTimeout(async () => {
      setSubmitLoading(false);
      setIsDeleteRequestModalOpen(false);
      setIsDeleteRequestSubmitted(true);
      sendDataDeletionRequest();
    }, 2000)
  }

  return (
    <div className={styles.container}>
      <PageHeader
        className={styles.pageHeader}
        title={t('profile.gdpr.title')}
        border={false}
      >
        {loading ? <SkeletonText /> : (
          isDeleteRequestSubmitted ? t('profile.gdpr.subtextSubmitted') : t('profile.gdpr.subtext')
        )}
      </PageHeader>

      {loading ? <SkeletonText /> : (
        !isDeleteRequestSubmitted && <Button
          as='button'
          color='neutral'
          size='sm'
          type='button'
          variant='filled'
          onClick={() => setIsDeleteRequestModalOpen(true)}
        >
          {t('profile.gdpr.deleteButton')}
        </Button>
      )}

      <Dialog
        title={t('profile.gdpr.deletelDialogTitle')}
        isOpen={isDeleteRequestModalOpen}
        size='sm'
        showCloseButton={false}
        primaryButton={
          <Button onClick={onDeleteRequest} variant='filled' loading={submitLoading}>
            {t('profile.gdpr.deleteDialogPrimaryButton')}
          </Button>
        }
        secondaryButton={
          <Button onClick={() => setIsDeleteRequestModalOpen(false)} variant='ghost'>
            {t('profile.gdpr.deleteDialogSecondaryButton')}
          </Button>
        }
      >
        <Trans i18nKey='profile.gdpr.deleteDialogContent' />
      </Dialog>
    </div>
  )
};

export default GDPRRequest;
