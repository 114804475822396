import { useContext, useEffect } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import {
  GET_ALL_PAYMENT_HISTORY_QUERY_NAME,
  PaymentHistoryGqlResponseType,
  QUERY_ALL_PAYMENTS
} from 'graphql/queries/PaymentQueries';

const usePayments = () => {
  const paymentsContext = useContext(PaymentsContext);
  const { data } = useQueryWithErrors<PaymentHistoryGqlResponseType>(
    GET_ALL_PAYMENT_HISTORY_QUERY_NAME,
    QUERY_ALL_PAYMENTS
  );

  useEffect(() => {
    if (data?.paymentsYearToDateTotal && data?.allOrSelectedPayments) {
      paymentsContext?.setPaymentsData({
        currency: data.paymentsYearToDateTotal.currency,
        yearToDateTotal: data.paymentsYearToDateTotal.value,
        completedPayments: data.allOrSelectedPayments.filter(payment => payment.details.status === 'completed_success'),
        incomingPayments: data.allOrSelectedPayments.filter(payment => payment.details.status !== 'completed_success')
      })
    }
  }, [data]);

  return paymentsContext?.paymentsData;
};

export default usePayments;
