import { PaymentType } from 'utils/types/paymentType';

export const sortPaymentsByDate = (payments?: PaymentType[] | null) => {
  return payments?.sort((paymentA, paymentB) => {
    const dateA: number = paymentA.details.transferSettledAt ? new Date(paymentA.details.transferSettledAt).valueOf() : 0
    const dateB: number = paymentB.details.transferSettledAt ? new Date(paymentB.details.transferSettledAt).valueOf() : 0

    return dateB - dateA;
  })
};

export const sortIncomingPaymentsByDate = (payments?: PaymentType[] | null) => {
  return payments?.sort((paymentA, paymentB) => {
    const dateA: number = paymentA.deliveryDateRangeStart ? new Date(paymentA.deliveryDateRangeStart).valueOf() : 0
    const dateB: number = paymentB.deliveryDateRangeStart ? new Date(paymentB.deliveryDateRangeStart).valueOf() : 0

    return dateA - dateB;
  })
};
