import { Trans, useTranslation } from 'react-i18next';
import { LogoIcon } from 'assets/milo';
import styles from './LoggedOutLayout.module.css';
import { Outlet } from 'react-router';
import { AlertsContainer } from 'components/AlertsContainer';

const LoggedOutLayout = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.shortTitle}>{t('account.welcomeShort')}</div>

        <LogoIcon variant='black' className={styles.logo} />

        <div className={styles.title}>
          <Trans i18nKey='account.welcomeLogin'>
            <span className={styles.highlightedText}>started.</span>
          </Trans>
        </div>
      </header>

      <main className={styles.main}>
        <div className={styles.mainContent}>
          <div className={styles.alertContainer}>
            <AlertsContainer alignContent='center'/>
          </div>

          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </main>

      <footer className={styles.footer}>
        <small className={styles.smallText}>{t('common.copyright', { currentYear: new Date().getFullYear() })}</small>
      </footer>
    </div>
  );
}

export default LoggedOutLayout;
