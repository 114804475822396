export default /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W_]).{8,}$/

export const passwordValidationHelpers = [
  {
    i18nKey: 'formValidations.helpers.password.lowercase',
    validator: (value: string) => /[a-z]/.test(value)
  },
  {
    i18nKey: 'formValidations.helpers.password.uppercase',
    validator: (value: string) => /[A-Z]/.test(value)
  },
  {
    i18nKey: 'formValidations.helpers.password.number',
    validator: (value: string) => /\d/.test(value)
  },
  {
    i18nKey: 'formValidations.helpers.password.symbol',
    validator: (value: string) => /[\W_]/.test(value)
  },
  {
    i18nKey: 'formValidations.helpers.password.length',
    validator: (value: string) => value.length >= 8
  }
];
