import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ActionFooter from 'components/ActionFooter';

interface Props {
  className?: string;
  children: any;
}

const FormFooter : React.FC<Props> = ({ className, children, ...otherProps }) => (
  <ActionFooter className={classNames('FormFooter', className)} {...otherProps}>
    {children}
  </ActionFooter>
);

FormFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default FormFooter;
