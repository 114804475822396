export const LAYOUTS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

export const SPACING = {
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const METHODS = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  PUT: 'put',
  DELETE: 'delete'
};
