import { useState, createContext, Dispatch, SetStateAction } from 'react';

export type OnboardingContextType = {
  isOnboarded: boolean,
  setIsOnboarded: Dispatch<SetStateAction<boolean>>
}

export const OnboardingContext = createContext<OnboardingContextType | null>(null);

export const OnboardingContextProvider = ({ children }: { children: JSX.Element }) => {
  // the value that will be given to the context
  const [isOnboarded, setIsOnboarded] = useState(false);

  return (
    // the Provider gives access to the context to its children
    <OnboardingContext.Provider value={{ isOnboarded, setIsOnboarded }}>
      {children}
    </OnboardingContext.Provider>
  );
};
