import './SelectInput.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { VARIANTS } from './constants';

/**
 * **DEPRECATED** - Please use the [StyledSelectInput](../StyledSelectInput) instead.
 *
 * A **SelectInput** is a styled, single-select dropdown.
 *
 * All unlisted props are passed directly to the underlying `select` element.
 * See the [React form docs](https://facebook.github.io/react/docs/forms.html) for more info about what props are supported.
 */
export default class SelectInput extends Component {
  static propTypes = {
    /** Custom classes to merge with the root class name */
    className: PropTypes.string,

    /** The initial, default display of the select */
    placeholder: PropTypes.string,

    /** The options that can be chosen from the select */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        /** The value of the option */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,

        /** The displayed text for the option */
        description: PropTypes.string.isRequired,

        /** If the option is disabled */
        disabled: PropTypes.bool
      })
    ).isRequired,

    /**
     * The current value of the select, which should match the `value`
     * of one of the options, or `""` to select the placeholder.
     */
    value: PropTypes.any,

    /** The visual variant of the select */
    variant: PropTypes.oneOf([
      /** The normal display */
      VARIANTS.DEFAULT,
      /** An alternative, all-caps/heading style display */
      VARIANTS.FRAMELESS
    ]),

    /** Whether to hide the svg down caret from select */
    hideSvg: PropTypes.bool
  };

  static defaultProps = {
    variant: VARIANTS.DEFAULT,
    hideSvg: false
  };

  render() {
    const { className, placeholder, options, variant, value, hideSvg, ...otherProps } =
      this.props;

    return (
      <div
        className={classNames(
          'SelectInput',
          `variant-${variant}`,
          { placeholder: value === '' },
          className
        )}
      >
        <select value={value} {...otherProps}>
          {placeholder !== undefined && (
            <option value='' disabled={true}>
              {placeholder}
            </option>
          )}
          {options.map(option => {
            return (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.description}
              </option>
            );
          })}
        </select>

        {variant === VARIANTS.FRAMELESS && (
          <div className='selected-option-description'>
            {this.getSelectedOptionDescription()}
          </div>
        )}

        {
          !hideSvg &&
          <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
            <g id='Arrow_Down'>
              <path fill='currentColor' d='M10,13a1,1,0,0,1-.71-.29l-4-4A1,1,0,0,1,6.71,7.29L10,10.59l3.29-3.29a1,1,0,0,1,1.41,1.41l-4,4A1,1,0,0,1,10,13Z'/>
            </g>
          </svg>
        }

      </div>
    );
  }

  // generate the text to display for the frameless variant
  getSelectedOptionDescription() {
    const { options, value, placeholder } = this.props;

    // get the selected option if there is one...
    const selectedOptionDescription = options.filter(option => {
      return option.value === value;
    })[0];

    // ...otherwise, show the placeholder if nothing is selected
    if (!selectedOptionDescription && !this.props.value && placeholder) {
      return placeholder;
    }

    return selectedOptionDescription ? selectedOptionDescription.description : null;
  }
}
