import React from 'react';
import styles from './ProfileCard.module.css';
import { Box } from '@justworkshr/milo-components';

interface ProfileCardProps {
  title: string;
  legalName?: string;
  preferredName?: React.ReactNode;
  pronouns?: React.ReactNode;
  birthday?: string;
  jobDescription?: string;
  website?: React.ReactNode;
  address?: string;
  phoneNumber?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  title,
  legalName,
  preferredName,
  pronouns,
  birthday,
  jobDescription,
  website,
  address,
  phoneNumber
}) => {
  let websiteHref = typeof website === 'string' ? website : undefined;
  if (!websiteHref?.startsWith('https://') && !websiteHref?.startsWith('http://') && typeof website === 'string') {
    websiteHref = 'https://' + websiteHref;
  }

  return (
    <div className={styles.profilecard}>
      <h1>{title}</h1>
      <Box
        backgroundColor='neutral'
        border={{
          borderColor: 'neutral',
          borderRadius: 'md',
          borderWidth: 'sm'
        }}
        padding='md'
      >
        <div className={styles.columns}>
          <div className={styles.column}>
            {legalName && (
              <div className={styles.item}>
                <strong>Legal name</strong>
                <p>{legalName}</p>
              </div>
            )}
            {preferredName && (
              <div className={styles.item}>
                <strong>Preferred name</strong>
                <p>{preferredName}</p>
              </div>
            )}
            {pronouns && (
              <div className={styles.item}>
                <strong>Pronouns</strong>
                <p>{pronouns}</p>
              </div>
            )}
            {birthday && (
              <div className={styles.item}>
                <strong>Birthday</strong>
                <p>{birthday}</p>
              </div>
            )}
            {address && (
              <div className={styles.item}>
                <strong>Home & Mailing address</strong>
                <p className={styles.formattedAddress}>{address}</p>
              </div>
            )}
          </div>
          <div className={styles.column}>
            {jobDescription && (
              <div className={styles.item}>
                <strong>Job description</strong>
                <p>{jobDescription}</p>
              </div>
            )}
            {website && (
              <div className={styles.item}>
                <strong>Website</strong>
                <p>
                  <a href={websiteHref}>{website}</a>
                </p>
              </div>
            )}
            {phoneNumber && (
              <div className={styles.item}>
                <strong>Phone number</strong>
                <p>{phoneNumber}</p>
              </div>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ProfileCard;
