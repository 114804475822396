import { createContext, ReactElement, ReactNode, useContext, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

export enum AlertTypes {
  Success = 'success',
  Warn = 'warning',
  Error = 'error',
  Notice = 'notice'
}

export type AlertType = {
  message: string | ReactElement
  type: AlertTypes,
  doNotClearOnLocations?: string[],
  key?: string
}

export const AppAlertsContext = createContext<
  {state: State; dispatch: Dispatch, pushAlerts: Function, clearAlert: Function, clearAlerts: Function } | undefined
  >(undefined)

type ActionTypes = 'pushAlerts' | 'clearAlert' | 'clearAlerts'
type Action = {type: ActionTypes, payload: any}
type Dispatch = (action: Action) => void
type State = {appAlerts: AlertType[]}
type AppAlertsProviderProps = {children: ReactNode, initialState?: object}

export const PUSH_ALERTS = 'pushAlerts'
export const CLEAR_ALERT = 'clearAlert'
export const CLEAR_ALERTS = 'clearAlerts'

export const alertsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case PUSH_ALERTS: {
      const newAlerts: AlertType[] = []
      action.payload.forEach((alert: AlertType) => {
        if (alert?.key) {
          if (!state.appAlerts.map((el) => el?.key).includes(alert.key)) {
            newAlerts.push(alert)
          }
        } else {
          newAlerts.push(alert)
        }
      })

      return { appAlerts: [...state.appAlerts, ...newAlerts] }
    }
    case CLEAR_ALERT: {
      const index = action.payload
      const newAppAlerts = state.appAlerts.filter((alert, idx) => idx !== index)
      return { appAlerts: newAppAlerts }
    }
    case CLEAR_ALERTS: {
      const currentLocation = action.payload
      const newAlerts = state.appAlerts.filter((alert) => alert.doNotClearOnLocations?.includes(currentLocation))
      return { appAlerts: newAlerts }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const mapDispatchToAction = (dispatch: Dispatch, type: ActionTypes) => (payload: any) => dispatch({ type, payload })

export const AppAlertsProvider = ({ children, initialState = { appAlerts: [] } }: AppAlertsProviderProps) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(alertsReducer, initialState)
  const location = useLocation();

  const pushAlerts = mapDispatchToAction(dispatch, PUSH_ALERTS)
  const clearAlertsDispatch = mapDispatchToAction(dispatch, CLEAR_ALERTS)
  const clearAlerts = () => clearAlertsDispatch(location.pathname)
  const clearAlert = mapDispatchToAction(dispatch, CLEAR_ALERT)

  const value = { state, dispatch, pushAlerts, clearAlert, clearAlerts }
  return (
    <AppAlertsContext.Provider value={value}>
      {children}
    </AppAlertsContext.Provider>
  )
}

export const useAppAlerts = () => {
  const context = useContext(AppAlertsContext)
  if (context === undefined) {
    throw new Error('useAppAlerts must be used within a AppAlertsContext')
  }
  return context
}

export default AppAlertsProvider
