import { gql } from '@apollo/client';
import { AddressDetailType } from 'graphql/types/AddressTypes';

export type QueryAllAddressDetailsResType = {
  allAddressDetails: AddressDetailType[]
}

export const GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME = 'GetCurrentAddressDetails';

export type QueryCurrentAddressDetailsResType = {
  currentAddressDetails: AddressDetailType
}

export const QUERY_CURRENT_ADDRESS_DETAILS = gql`
  query ${GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME} {
    currentAddressDetails {
      id
      addressId
      countryCode
      streetLine1
      streetLine2
      city
      state
      postalCode
    }
  }
`
