export default /^([A-Za-z])([A-Za-z0-9]){3,39}$/

export const usernameValidationHelpers = [
  {
    i18nKey: 'formValidations.helpers.username.startLetter',
    validator: (value: string) => /^([A-Za-z])/.test(value)
  },
  {
    i18nKey: 'formValidations.helpers.username.onlyLettersAndNumbers',
    validator: (value: string) => /^([A-Za-z0-9]){4,}$/.test(value)
  },
  {
    i18nKey: 'formValidations.helpers.username.length',
    validator: (value: string) => value.length >= 4 && value.length <= 40
  }
];
