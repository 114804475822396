import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo } from 'react';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

interface FeatureFlagsContextValue {
  flags: LDFlagSet;
  loading: boolean;
  error: Error | null;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextValue | undefined>(undefined);

export const FeatureFlagProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const ldClient = useLDClient();
  const flags = useFlags();

  useEffect(() => {
    if (ldClient) {
      ldClient.waitUntilReady()
        .then(() => setLoading(false))
        .catch(setError);
    }
  }, [ldClient]);

  const contextValue = useMemo(() => ({ flags, loading, error }), [flags, loading, error]);

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
};
