import { useEffect, useState } from 'react';
import AddressFormatter, { Country, FieldName } from '@shopify/address';
import { useTranslation } from 'react-i18next';

export const useAddressFormatter = (countryCode: string) => {
  const [country, setCountry] = useState<Country | null>(null);
  const [formatter, setFormatter] = useState<AddressFormatter | null>(null);
  const [orderedFields, setOrderedFields] = useState<FieldName[][] | null>(
    null
  );
  const { i18n } = useTranslation();
  const locale = i18n.language;

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const formatter = new AddressFormatter(locale);
        const countryReturn = await formatter.getCountry(countryCode);
        const fields = await formatter.getOrderedFields(countryCode);
        setCountry(countryReturn);
        setFormatter(formatter);
        setOrderedFields(fields);
      } catch (error) {
        console.error('Error fetching country:', error);
        setCountry(null);
        setFormatter(null);
      }
    };

    if (countryCode) {
      fetchCountry();
    }
  }, [locale, countryCode, i18n]);

  return { country, formatter, orderedFields };
};
