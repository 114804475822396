import { useState, createContext, Dispatch, SetStateAction } from 'react';

import { PaymentType } from 'utils/types/paymentType';

type PaymentsDataType = {
  yearToDateTotal?: number,
  completedPayments?: PaymentType[],
  incomingPayments?: PaymentType[],
  currency?: string
}

export type PaymentsContextType = {
  paymentsData: PaymentsDataType
  setPaymentsData: Dispatch<SetStateAction<PaymentsDataType>>
}

export const PaymentsContext = createContext<PaymentsContextType | null>(null);

export const PaymentsContextProvider = ({ children }: { children: JSX.Element }) => {
  // the value that will be given to the context
  const [paymentsData, setPaymentsData] = useState<PaymentsDataType>({
    yearToDateTotal: 0
  });

  return (
    // the Provider gives access to the context to its children
    <PaymentsContext.Provider value={{ paymentsData, setPaymentsData }}>
      {children}
    </PaymentsContext.Provider>
  );
};
