import { gql } from '@apollo/client';

type SystemDetailsType = {
  timestamp: string;
  env: string;
  codeVersion: string;
  // TODO: set up Feature Flags
  // featureFlags: object;
}

export type QuerySystemDetailsResType = {
  systemDetails: SystemDetailsType
}

export const queryName = 'systemDetails';

export const QUERY_SYSTEM_DETAILS = gql`
  {
    ${queryName} {
      timestamp
      env
      codeVersion
    }
  }
`;
