import { gql } from '@apollo/client';

export const RESUME_INQUIRY_MUTATION_NAME = 'resumePersonaInquiry';

export const RESUME_INQUIRY = gql`
  mutation(
    $inquiryId: String!
  ){
    ${RESUME_INQUIRY_MUTATION_NAME}(
      input: {
        inquiryId: $inquiryId
      }
    ) {
      inquiryStatus
      sessionToken
      errors
    }
  }
`
