import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentType } from 'utils/types/paymentType';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { formatDate } from 'utils/helpers/formatDate';
import styles from './PaymentsTable.module.css';

type Props = {
  completedPayments: PaymentType[],
  currency: string
}

const DesktopTable = ({ completedPayments, currency }: Props) => {
  const { formatCurrency } = useContext(SupportedCountriesContext);
  const { t } = useTranslation();

  return (
    <table className={styles.desktopTable}>
      <thead>
        <tr>
          <th className={styles.dateCol}>{t('payments.date')}</th>
          <th className={styles.companyCol}>{t('payments.company')}</th>
          <th className={styles.usdAmountCol}>{t('payments.currencyAmount', { currency: 'usd' })}</th>
          <th className={styles.localAmountCol}>{t('payments.currencyAmount', { currency: currency })}</th>
        </tr>
      </thead>
      <tbody>
        {
          completedPayments.map((payment) => (
            <tr key={payment.id} className={styles.paymentRow}>
              <td className={styles.dateCol}>{formatDate(payment.details.disbursementSettledAt)}</td>
              <td className={styles.companyCol} data-dd-privacy='mask'>{payment.companyName}</td>
              <td className={styles.usdAmountCol}>
                <div className={styles.currencyContainer}>
                  <div>{formatCurrency(payment.details.collectionAmount, payment.details.collectionCurrency)}</div>
                  <div className={styles.currencyLabel}>{payment.details.collectionCurrency}</div>
                </div>
              </td>
              <td className={styles.localAmountCol}>
                <div className={styles.currencyContainer}>
                  <div>{formatCurrency(payment.details.disbursementAmountActual || 0, payment.details.disbursementCurrency)}</div>
                  <div className={styles.currencyLabel}>{payment.details.disbursementCurrency}</div>
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}

export default DesktopTable;
