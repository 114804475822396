const JustworksLogoOnDark = ({ className }: { className?: string }) => (
  <svg className={className} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 906.7 167.87' xmlSpace='preserve'>
    <rect x='434.28' y='-299.45' transform='matrix(-1.836970e-16 1 -1 -1.836970e-16 602.1429 -304.2016)' style={{ fill: '#52B0FF' }} width='37.79' height='896.84'/>
    <g>
      <path style={{ fill: '#FFFFFF' }} d='M0,92.02l31.55-6.05c0.57,16.25,5.67,24,16.44,24c9.26,0,13.6-4.35,13.6-16.82V0h32.88v85.4
        c0,33.44-13.79,47.24-47.24,47.24C12.66,132.64,2.65,112.42,0,92.02z'/>
      <path style={{ fill: '#FFFFFF' }} d='M114.92,98.33V35.6h32.12v63.3c0,9.07,4.35,12.66,11.34,12.66c9.26,0,14.93-5.67,14.93-18.89V35.6
        h32.12v94.47h-30.61v-25.51h-0.76c-4.72,22.11-15.12,27.78-29.29,27.78C124.75,132.34,114.92,121.76,114.92,98.33z'/>
      <path style={{ fill: '#FFFFFF' }} d='M221.66,110.05l17.38-11.71c5.67,10.58,14.74,14.74,26.45,14.74c8.88,0,12.85-2.65,12.85-7.56
        c0-5.67-3.78-8.69-14.36-10.58l-13.23-2.46c-17.76-3.21-27.4-13.6-27.4-28.15c0-19.46,15.68-30.8,39.68-30.8
        c23.81,0,34.96,9.83,41,20.79l-16.82,10.77c-4.35-9.83-13.04-13.04-20.97-13.04c-8.31,0-12.66,3.02-12.66,7.94
        c0,4.72,3.59,7.37,12.66,9.07l13.42,2.46c19.84,3.59,29.48,11.71,29.48,29.29c0,16.63-10.58,31.55-41.76,31.55
        C244.33,132.34,228.65,123.46,221.66,110.05z'/>
      <path style={{ fill: '#FFFFFF' }} d='M328.22,104.57V54.49h-12.85V35.6h12.85V10.85h32.12V35.6h15.49v18.89h-15.49v47.24
        c0,6.24,2.46,8.69,7.18,8.69h8.31v19.65h-20.97C334.65,130.07,328.22,123.65,328.22,104.57z'/>
      <path style={{ fill: '#FFFFFF' }} d='M385.94,35.6h34.77l13.04,59.52h0.76l16.63-59.52h26.45l16.06,59.52h0.76l14.36-59.52h25.32
        l-29.29,94.47H477.2l-16.82-59.33h-0.76l-16.06,59.33h-27.59L385.94,35.6z'/>
      <path style={{ fill: '#FFFFFF' }} d='M537.07,82.84c0-30.23,19.65-49.5,49.13-49.5c29.48,0,49.13,19.27,49.13,49.5
        c0,30.23-19.65,49.5-49.13,49.5C556.72,132.34,537.07,113.07,537.07,82.84z M601.69,82.84c0-24.37-5.29-30.8-15.49-30.8
        c-10.2,0-15.49,6.42-15.49,30.8c0,24.37,5.29,30.8,15.49,30.8C596.4,113.64,601.69,107.21,601.69,82.84z'/>
      <path style={{ fill: '#FFFFFF' }} d='M649.47,35.6h30.23v31.37h0.76c4.53-25.89,13.23-31.93,27.21-31.93h1.7v28.34h-15.49
        c-9.26,0-12.28,3.02-12.28,14.17v52.53h-32.12V35.6z'/>
      <path style={{ fill: '#FFFFFF' }} d='M762.91,92.28l-9.26,10.2v27.59h-31.74V0.2h31.74v76.02l32.88-40.62h28.53l-31.18,34.01l33.25,60.46
        h-34.01L762.91,92.28z'/>
      <path style={{ fill: '#FFFFFF' }} d='M819.21,110.05l17.38-11.71c5.67,10.58,14.74,14.74,26.45,14.74c8.88,0,12.85-2.65,12.85-7.56
        c0-5.67-3.78-8.69-14.36-10.58l-13.23-2.46c-17.76-3.21-27.4-13.6-27.4-28.15c0-19.46,15.68-30.8,39.68-30.8
        c23.81,0,34.96,9.83,41,20.79l-16.82,10.77c-4.35-9.83-13.04-13.04-20.97-13.04c-8.31,0-12.66,3.02-12.66,7.94
        c0,4.72,3.59,7.37,12.66,9.07l13.42,2.46c19.84,3.59,29.48,11.71,29.48,29.29c0,16.63-10.58,31.55-41.76,31.55
        C841.89,132.34,826.2,123.46,819.21,110.05z'/>
    </g>
  </svg>
)

export default JustworksLogoOnDark;
