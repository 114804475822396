import { useTranslation } from 'react-i18next';
import { DocumentType } from 'utils/types/documentType';
import { formatDate } from 'utils/helpers/formatDate';
import styles from './DocumentsTable.module.css';
import { Button, SystemIcon } from '@justworkshr/milo-components';
import { TableSortingParams } from 'utils/types/tableTypes';
import { DOCUMENTS_TABLE_KEYS } from 'constants/documents';
import SortIcon from 'components/SortIcon';
import { NEXT_SORT_MAP, SORT } from 'constants/table';

type Props = {
  documents: DocumentType[],
  tableParams: TableSortingParams,
  onSort: (value: TableSortingParams) => void
}

const DesktopTable = ({ documents, tableParams, onSort }: Props) => {
  const { t } = useTranslation();

  const handleClick = (key: string) => {
    const nextDirection =
      key === tableParams.sortBy ? NEXT_SORT_MAP[tableParams.sortDirection] : SORT.ASC;

    onSort({ sortBy: key, sortDirection: nextDirection });
  };

  const renderHeader = (key: string, label: string) => {
    return (
      <th key={key}>
        <Button
          size='sm'
          type='button'
          variant='ghost'
          data-testid={key}
          className={`${styles.sortButton}`}
          onClick={() => handleClick(key)}
        >
          <span>{label}</span>
          <SortIcon
            sortKey={key}
            sortBy={tableParams.sortBy}
            sortDirection={tableParams.sortDirection}
          />
        </Button>
      </th>
    );
  }

  return (
    <table className={styles.desktopTable}>
      <thead>
        <tr>
          <th className={styles.nameCol}>
            {renderHeader(DOCUMENTS_TABLE_KEYS.NAME, t('documents.name'))}
          </th>
          <th className={styles.uploadedCol}>
            {renderHeader(DOCUMENTS_TABLE_KEYS.UPLOADED, t('documents.uploaded'))}
          </th>
        </tr>
      </thead>
      <tbody>
        {
          documents.map((document, index) => (
            <tr key={index}>
              <td className={styles.nameCellContainer}>
                <SystemIcon
                  iconName='document-pdf'
                  color='disabled'
                  size='small'
                />
                <div>
                  <div className={styles.nameCell}>{document.name}</div>
                  <div className={styles.categoryCell}>{document.category}</div>
                </div>
              </td>
              <td className={styles.uploadedCell}>{formatDate(document.uploadDate)}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}

export default DesktopTable;
