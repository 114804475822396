import './Notice.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TYPES } from './constants';
import { Button } from 'assets/milo';

/**
 * A **Notice** renders warnings or important supplementary text as a highlighted call-out.
 */

export default class Notice extends Component {
  static propTypes = {
    /** The content of the notice */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

    buttonText: PropTypes.string,

    /** Custom classes to merge with the root class name */
    className: PropTypes.string,

    /** The header for the notice content */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /** The figure to display, if any */
    figure: PropTypes.object,

    status: PropTypes.string,

    /** The type of the notice, which determines its style based on the nature of its content */
    type: PropTypes.oneOf([TYPES.WARNING, TYPES.INFO, TYPES.SUCCESS]).isRequired,

    fireConfetti: PropTypes.func
  };

  static defaultProps = {
    title: 'Attention',
    type: TYPES.WARNING
  };

  render() {
    const { children, className, title, figure, type, status, buttonText, fireConfetti } = this.props;
    const typeClassName = `${type}`;

    return (
      <>
        <section className={classNames('Notice', typeClassName, className)}>
          <div className={classNames('text')}>
            {figure && <div>{figure}</div>}
            {status && (
              <div className={classNames('status', status)}>
                {status}
              </div>
            )}
            <div className={classNames('title')}>
              {title && (
                <header>
                  <h4>{title}</h4>
                </header>
              )}
            </div>
            <div>
              {typeof children === 'string' ? <p>{children}</p> : children}
            </div>
          </div>
          <div>
            {buttonText && (
              <Button className={'button'} onClick={fireConfetti} >
                {buttonText}
              </Button>
            )}
          </div>
        </section>
      </>
    );
  }
}
