import { Dropdown } from 'components/Dropdown'
import { DOCUMENTS_TABLE_KEYS } from 'constants/documents'
import { SORT } from 'constants/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableSortingParams } from 'utils/types/tableTypes'
import styles from './DocumentsTable.module.css'
import Menu from 'components/Menu/Menu'
import MenuItem from 'components/Menu/MenuItem'

type Props = {
    tableParams: TableSortingParams
    onSort: (value: TableSortingParams) => void
}

const SortingMenu = ({ onSort, tableParams }: Props) => {
  const { t } = useTranslation();

  const [sortingMenuOpen, setSortingMenuOpen] = useState(false)

  const handleMenuItemClick = (params: TableSortingParams) => {
    onSort(params)
    setSortingMenuOpen(false)
  }

  const getSortByString = () => {
    switch (tableParams.sortBy) {
      case DOCUMENTS_TABLE_KEYS.NAME:
        return tableParams.sortDirection === SORT.ASC ? t('documents.sorting.nameAscending') : t('documents.sorting.nameDescending')
      case DOCUMENTS_TABLE_KEYS.UPLOADED:
      default:
        return tableParams.sortDirection === SORT.ASC ? t('documents.sorting.uploadedAscending') : t('documents.sorting.uploadedDescending')
    }
  }

  const menu = (
    <Menu>
        <MenuItem onClick={() => handleMenuItemClick({ sortBy: DOCUMENTS_TABLE_KEYS.NAME, sortDirection: SORT.ASC })}>
            {t('documents.sorting.nameAscending')}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick({ sortBy: DOCUMENTS_TABLE_KEYS.NAME, sortDirection: SORT.DESC })}>
            {t('documents.sorting.nameDescending')}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick({ sortBy: DOCUMENTS_TABLE_KEYS.UPLOADED, sortDirection: SORT.ASC })}>
            {t('documents.sorting.uploadedAscending')}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick({ sortBy: DOCUMENTS_TABLE_KEYS.UPLOADED, sortDirection: SORT.DESC })}>
            {t('documents.sorting.uploadedDescending')}
        </MenuItem>
    </Menu>
  )

  return (
        <Dropdown
            dropdownName='sort by dropdown'
            content={menu}
            open={sortingMenuOpen}
            onOpenChange={(newOpen) => {
              if (newOpen) {
                setSortingMenuOpen(true);
              } else {
                setSortingMenuOpen(false);
              }
            }}
            onClickOutside={() => {
              setSortingMenuOpen(false);
            }}
      >
        <span className={styles.sortDropdownToggleButton}>{getSortByString()}</span>
      </Dropdown>
  )
}

export default SortingMenu;
