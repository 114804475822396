import usePayments from 'hooks/usePayments';

import EarningsBanner from 'components/EarningsBanner';
import PaymentsTable from 'components/PaymentsTable';
import styles from './Payments.module.css';
import IncomingPaymentsTable from 'components/IncomingPaymentsTable';
import { useTranslation } from 'react-i18next';

const Payments = () => {
  const { t } = useTranslation();
  usePayments();

  return (
    <>
        <div className={styles.content}>
          <h1>{t('dashboard.paymentsTabTitle')}</h1>
          <h4>{t('dashboard.yearToDate')}</h4>
          <EarningsBanner />
          <IncomingPaymentsTable />
          <PaymentsTable />
        </div>
    </>
  );
};

export default Payments;
