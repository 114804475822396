import { useEffect, useContext } from 'react';
import PageTitle from 'contexts/PageTitle';

const usePageTitle = (newPageTitle: string) => {
  const pageTitleContext = useContext(PageTitle);

  useEffect(() => {
    if (pageTitleContext?.pageTitle !== newPageTitle) {
      pageTitleContext?.setPageTitle(newPageTitle);
    }
  }, [pageTitleContext, newPageTitle]);
};

export default usePageTitle;
