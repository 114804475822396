import { FocusEventHandler, FormEventHandler } from 'react';

import { FieldDataType } from 'utils/types/formTypes';
import shortid from 'shortid';
import {
  Select,
  SelectOption,
  TextInput
} from '@justworkshr/milo-form'
import FormFieldWrapper from '../FormFieldWrapper';
import FieldGroup from '../FieldGroup'
import FormikFormField from '../FormikFormField'
import { useTranslation } from 'react-i18next';

interface FieldType extends FieldDataType {
  value: string;
}

interface Props extends FieldType {
  className?: string | { [key: string]: string }
  fields?: FieldType[] | null;
  handleChange: FormEventHandler<HTMLInputElement | HTMLSelectElement>;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  optional?: boolean;
  id?: string;
  disabled?: boolean;
}

const AutoFormField = ({
  type,
  name,
  label,
  description,
  placeholder,
  optional,
  defaultValue,
  value,
  options,
  fields,
  handleChange,
  handleBlur,
  disabled
}: Props) => {
  const generateField = (id: string) => {
    const { t } = useTranslation();

    switch (type) {
      case 'group':
        return (
          <FieldGroup>
            {fields?.map((field) => (
              <AutoFormField
                key={field.name}
                type={field.type}
                name={`${name}.${field.name}`}
                label={field.label}
                placeholder={field.placeholder}
                description={field.description}
                optional={!field.validations?.required?.enabled}
                handleChange={handleChange}
                handleBlur={handleBlur}
                defaultValue={defaultValue}
                value={field.value}
                id={id}
                disabled={disabled}
              />
            ))}
          </FieldGroup>
        )
      case 'text':
      case 'number':
        return (
          <TextInput
            key={name}
            name={name}
            type={type}
            placeholder={placeholder as string | undefined}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={defaultValue as string | undefined}
            value={value}
            id={id}
            disabled={disabled}
          />
        )
      case 'select':
        return (
          <Select
            key={name}
            name={name}
            defaultValue={defaultValue as string | number | undefined}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            id={id}
            placeholder={placeholder || t('common.selectOne') as string | undefined}
            disabled={disabled}
          >
            {(options || []).map((option) => <SelectOption key={option.value} value={option.value}>{option.description}</SelectOption>)}
          </Select>
        )
      default:
        return (
          <div>{type}</div>
        )
    }
  }

  const id = shortid.generate();

  return (
    <FormFieldWrapper>
      <FormikFormField
        id={id}
        name={name}
        label={label || ''}
        message={description as string | undefined}
        required={!optional}
      >
        {generateField(id)}
      </FormikFormField>
    </FormFieldWrapper>
  )
}

export default AutoFormField
