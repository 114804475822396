import { gql } from '@apollo/client';

export const GET_CURRENT_COMPANY_DETAILS_QUERY_NAME = 'getCurrentCompanyDetailsById';

export type QueryCurrentCompanyDetailByIdResType = {
  getCurrentCompanyDetailsById: {
    name: string,
  }
};

export const QUERY_CURRENT_COMPANY_DETAILS_BY_ID = gql`
  query ($id: ID!) {
    ${GET_CURRENT_COMPANY_DETAILS_QUERY_NAME} (id: $id) {
      name
    }
  }
`;
