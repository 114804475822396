import React from 'react';

type Props = {
  className?: string;
}

const defaultProps = {
  className: undefined
};

const Pencil = ({ className }: Props) => (
  <svg className={className} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M14.3 3.03079L11.56 0.290793C11.17 -0.0969309 10.5401 -0.0969309 10.15 0.290793L0.290017 10.1508C0.10283 10.3395 -0.00152002 10.595 1.67363e-05 10.8608V14.1508C1.67363e-05 14.7031 0.447732 15.1508 1.00002 15.1508H1.20002L3.94002 14.6008C4.13286 14.5629 4.31024 14.469 4.45002 14.3308L14.31 4.47079C14.5043 4.28095 14.6129 4.02027 14.6111 3.74867C14.6092 3.47707 14.4969 3.21791 14.3 3.03079ZM3.24002 12.6808L1.99002 12.9308V11.2708L8.66002 4.60079L9.98002 5.92079L3.24002 12.6808ZM10.07 3.19079L11.4 4.52079L12.17 3.73079L10.85 2.41079L10.07 3.19079Z' fill='#1E98FF'/>
  </svg>

);

Pencil.defaultProps = defaultProps;

export default Pencil
