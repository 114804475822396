import { useContext, useEffect } from 'react';
import { DocumentsContext } from 'contexts/Documents';
import { DocumentMemberAssignment, DocumentType } from 'utils/types/documentType';
import useQueryWithErrors from './useQueryWithErrors';
import { GET_CURRENT_MEMBER_DETAILS_QUERY_NAME, QUERY_CURRENT_MEMBER_DETAILS } from 'graphql/queries/MemberDetailQueries';

const allDocuments: DocumentType[] = [
  {
    category: 'Category',
    companyId: '1',
    name: '2023 Engineering Handbook',
    documentId: 'e1e5d337-4699-42f7-87d7-a827e20cab5b',
    owner: 'Justworks',
    remindersSet: true,
    signatureRequired: true,
    uploadDate: '2024-01-01',
    documentMemberAssignments: [{
      companyId: '1',
      documentMemberAssignmentId: '1',
      member: {
        uuid: 'b3686081-bd0e-45c3-b356-19922877dfb6'
      },
      visibilityLevel: 'admin_and_contractor'
    }]
  },
  {
    category: 'Category',
    companyId: '1',
    name: 'File Naming Conventions - Engineering and Public Policy',
    documentId: 'e1e5d337-4699-42f7-87d7-a827e20cab5c',
    owner: 'Justworks',
    remindersSet: true,
    signatureRequired: true,
    uploadDate: '2023-01-01',
    documentMemberAssignments: [{
      companyId: '1',
      documentMemberAssignmentId: '2',
      member: {
        uuid: 'b3686081-bd0e-45c3-b356-19922877dfb6'
      },
      visibilityLevel: 'admin_and_contractor'
    }]
  },
  {
    category: 'Category',
    companyId: '1',
    name: 'Logins and Passwords',
    documentId: 'e1e5d337-4699-42f7-87d7-a827e20cab5d',
    owner: 'Justworks',
    remindersSet: true,
    signatureRequired: true,
    uploadDate: '2023-01-01',
    documentMemberAssignments: [{
      companyId: '1',
      documentMemberAssignmentId: '3',
      member: {
        uuid: 'b3686081-bd0e-45c3-b356-19922877dfb6'
      },
      visibilityLevel: 'admin_only'
    }]
  }
]

const useDocuments = () => {
  const documentsContext = useContext(DocumentsContext);

  const { data: memberData } = useQueryWithErrors(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS
  )

  const memberId = memberData?.currentMemberDetails?.memberId

  useEffect(() => {
    const accessibleDocuments = allDocuments.filter(document => {
      const documentMemberAssignment = document.documentMemberAssignments.find((assignment: DocumentMemberAssignment) => assignment.member.uuid === memberId)
      return documentMemberAssignment?.visibilityLevel === 'admin_and_contractor'
    })

    documentsContext?.setDocumentsData({
      documents: accessibleDocuments
    })
  }, []);

  return documentsContext?.documentsData;
};

export default useDocuments;
