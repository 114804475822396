import React from 'react';
import { ErrorMessage } from 'formik';

import styles from './Styles.module.css';

type Props = {
  name: string;
  testId?: string;
}

const FormFieldErrorMessage = ({ name, testId }: Props) => (
  <ErrorMessage name={name}>
    { (msg: string) => {
      if (typeof msg !== 'string') return null;
      return <div
        id='form-error-message'
        className={styles.errorMessage}
        data-testid={testId}
      >
        { msg }
      </div>
    }
    }
  </ErrorMessage>
)

export default FormFieldErrorMessage;
